import React, { useState } from "react";
import Answer from "./Answer";

const ScreeningQuestionForm = ({
  questions,
  setQuestions,
  theme,
  isTemplateUsed,
}) => {
  // console.log("Heloow");

  console.log("Questions are in form  :", questions);

  const setAnswer = (quIdx, answer) => {
    const updatedArray = questions.map((item, idx) => {
      if (quIdx === idx) return { ...item, candidateResponse: answer };
      return item;
    });
    setQuestions(updatedArray);
  };

  return (
    <div className="w-full flex flex-col gap-6 items-start">
      {questions.map((question, index) => (
        <div key={index} className="w-full flex flex-col gap-2 items-start">
          <div className="w-full flex flex-col gap-1 items-start">
            <label
              className={`block text-gray-800 font-semibold mb-2 text-sm ${question?.required ? "required after:contents-['*'] after:text-red-800" : question?.required}`}
            >
              {question.question}
            </label>
            <Answer
              question={questions}
              required={question.required}
              answer={question.answer}
              answerType={question.answerType}
              candidateAnswer={setAnswer}
              index={index}
              theme={theme}
              isTemplateUsed={isTemplateUsed}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default ScreeningQuestionForm;
