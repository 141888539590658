import React, { useContext, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import TemplateForm from "./TemplateForm";

const core = require("../../../lib/core");

const CreateTemplate = () => {
  const { reloadList } = useOutletContext();
  const nav = useNavigate();
  const { user } = useContext(core.UserContext);
  const [form, setForm] = useState({});
  const [message, setMessage] = useState({});
  const [loading, setLoading] = useState(false);
  const [bodyLen, setBodyLen] = useState(0);
  const [attachments, setAttachments] = useState([]);

  const setData = (key, value) => {
    setForm({ ...form, [key]: value });
  };

  const createTemplate = async (attachmentlinks) => {
    try {
      setLoading(true);
      const json = await core.API(
        core.API_METHODS.POST,
        core.TEMPLATE_API.POST_CREATE_TEMPLATE,
        1,
        {
          template_name: form?.name,
          template_type: form?.type,
          subject: form?.subject,
          body: form?.body,
          creator: user?.id,
          attachments: attachmentlinks ?? [],
        },
      );
      if (json?.data) {
        setMessage({
          type: "success",
          message: "Template created successfully",
        });
        reloadList();
        setTimeout(() => {
          setMessage({});
          setLoading(false);
          nav("/templates/email/" + json?.data?.data?._id);
        }, 2500);
      } else {
        setLoading(false);
        setMessage({
          type: "error",
          message: json?.message || "Error creating template",
        });
      }
    } catch (error) {
      console.error("Error creating template", error);
      setLoading(false);
      setMessage({
        type: "error",
        message: error?.response?.data?.message || "Error creating template",
      });
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (
      !(
        form?.name?.trim()?.length > 0 &&
        form?.type?.trim()?.length > 0 &&
        form?.subject?.trim()?.length > 0 &&
        bodyLen > 0
      )
    ) {
      setLoading(false);
      setMessage({
        type: "error",
        message: "Please enter all required data",
      });
      return;
    }

    const formData = new FormData();
    const token = await core.getData(core.data.ACCESS_TOKEN);

    // Append each file to the FormData object
    for (let i = 0; i < attachments.length; i++) {
      formData.append("files", attachments[i]);
    }

    try {
      if (attachments.length > 0) {
        await fetch(core.S3_API.POST_UPLOAD_FILE, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
            uid: user?.id,
          },
          body: formData,
        })
          .then((res) => res.json())
          .then((data) => {
            console.log(data?.data, data);
            createTemplate(data?.data);
          });
      } else {
        createTemplate();
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      setLoading(true);
    }
  };

  return (
    <div
      className={`w-full grow relative p-4 rounded-lg bg-white flex flex-col gap-4 ${loading ? "pointer-events-none" : ""}`}
    >
      <h1 className="text-black text-base font-medium not-italic">
        Create new template
      </h1>
      <TemplateForm
        formData={form}
        setData={setData}
        bodyCharCount={setBodyLen}
        setAttachments={setAttachments}
        attachments={attachments}
      />
      <div className="w-full flex items-center gap-4 flex-row-reverse self-end">
        <button
          disabled={loading}
          onClick={handleSubmit}
          className="btn-md btn-primary"
        >
          Save
        </button>
        <button
          disabled={loading}
          onClick={() => nav("/templates")}
          className="btn-md btn-sec"
        >
          Cancel
        </button>
        {Object?.entries(message)?.length > 0 && (
          <p
            style={
              message?.type == "error" ? { color: "red" } : { color: "green" }
            }
            className="block text-sm font-medium grow"
          >
            {message?.message}
          </p>
        )}
      </div>
    </div>
  );
};

export default CreateTemplate;
