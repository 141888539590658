// import React, { useEffect, useState } from "react";
// import $ from "jquery";
// import InputBox from "../../../layouts/InputBox";

// export default function AutocompleteInput({
//   list,
//   idx,
//   setvalue,
//   initialArray,
//   classText,
// }) {
//   const [hide, setHide] = useState([]);

//   $(document).on("click", function (e) {
//     if ($(e.target).is("#" + idx + ".auto-complete-input") === false) {
//       setHide(true);
//     } else {
//       setHide(false);
//     }
//   });
//   const [tempValue, setTempValue] = useState();
//   const [searchList, setSearchList] = useState();

//   const search = (e) => {
//     const filteredItems = list?.filter((item) =>
//       item?.toLowerCase()?.includes(e?.toLowerCase()),
//     );
//     setSearchList(filteredItems);
//   };

//   const [searchValue, setSearchValue] = useState();

//   useEffect(() => {
//     setSearchList([searchValue]);
//   }, [searchValue]);

//   return (
//     <div className={idx} id={idx}>
//       <InputBox
//         placeholder="Search..."
//         className={` auto-complete-input ${classText}`}
//         size={"small"}
//         onChange={(e) => {
//           search(e.target.value);
//           setSearchValue(e.target.value);
//           setHide(false);
//         }}
//       />
//       {searchList?.length > 0 && (
//         <ul
//           className={
//             hide
//               ? "auto-complete-list hide"
//               : "auto-complete-list  bg-white  no-list-style py-[20px]  rounded-lg  shadow-menuOptionShadow z-10"
//           }
//         >
//           {searchList
//             ?.filter((element) => !initialArray?.includes(element))
//             .map((item, i) => (
//               <li
//                 key={i}
//                 className="match-value px-[20px] py-[10px]"
//                 onClick={() => {
//                   $("#" + idx + " .auto-complete-input").val("");
//                   // console.log($("#" + idx + " .auto-complete-input"))
//                   setvalue([...initialArray, item]);
//                   setTempValue();
//                   setHide(true);
//                 }}
//               >
//                 {item}
//               </li>
//             ))}
//         </ul>
//       )}
//     </div>
//   );
// }

// import React, { useEffect, useState } from "react";
// import $ from "jquery";
// import InputBox from "../../../layouts/InputBox";

// export default function AutocompleteInput({
//   list,
//   idx,
//   setvalue,
//   initialArray,
//   classText,
//   fetchSuggestions,
//   fetchJobSuggestions
// }) {
//   const [hide, setHide] = useState([]);
//   const [searchValue, setSearchValue] = useState("");
//   const [searchList, setSearchList] = useState(list);

//   // console.log("Search List is : ", searchList);

//   // Synchronize searchList with the list prop whenever it changes
//   useEffect(() => {
//     setSearchList(list);
//   }, [list]);

//   $(document).on("click", function (e) {
//     if ($(e.target).is("#" + idx + ".auto-complete-input") === false) {
//       setHide(true);
//     } else {
//       setHide(false);
//     }
//   });

//   const search = async (value) => {
//     if (fetchSuggestions) {
//       // Use the provided fetchSuggestions function to get the list
//       await fetchSuggestions(value);
//     } else {
//       // Default search logic
//       const filteredItems = list?.filter((item) =>
//         item?.toLowerCase()?.includes(value?.toLowerCase()),
//       );
//       setSearchList(filteredItems);
//     }
//   };

//   useEffect(() => {
//     if (!fetchSuggestions) {
//       setSearchList([searchValue]);
//     }
//   }, [searchValue]);

//   return (
//     <div className={idx} id={idx}>
//       <InputBox
//         placeholder="Search..."
//         className={`auto-complete-input ${classText}`}
//         size={"small"}
//         onChange={(e) => {
//           setSearchValue(e.target.value);
//           search(e.target.value); // Trigger search or fetchSuggestions
//           setHide(false);
//         }}
//       />
//       {searchList?.length > 0 && (
//         <ul
//           className={
//             hide
//               ? "auto-complete-list hide"
//               : "auto-complete-list bg-white no-list-style py-[20px] rounded-lg shadow-menuOptionShadow z-10 max-h-48 overflow-y-auto"
//           }
//         >
//           {searchList
//             ?.filter((element) => !initialArray?.includes(element))
//             .map((item, i) => (
//               <li
//                 key={i}
//                 className="match-value px-[20px] py-[10px]"
//                 onClick={() => {
//                   $("#" + idx + " .auto-complete-input").val("");
//                   setvalue([...initialArray, item]);
//                   setHide(true);
//                 }}
//               >
//                 {item}
//               </li>
//             ))}
//         </ul>
//       )}
//     </div>
//   );
// }

import React, { useEffect, useState } from "react";
import $ from "jquery";
import InputBox from "../../../layouts/InputBox";

export default function AutocompleteInput({
  list,
  idx,
  setvalue,
  initialArray,
  classText,
  fetchSuggestions, // optional prop for skills
  fetchJobSuggestions, // optional prop for job titles
}) {
  const [hide, setHide] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchList, setSearchList] = useState(list);

  // Synchronize searchList with the list prop whenever it changes
  useEffect(() => {
    setSearchList(list);
  }, [list]);

  $(document).on("click", function (e) {
    if ($(e.target).is("#" + idx + ".auto-complete-input") === false) {
      setHide(true);
    } else {
      setHide(false);
    }
  });

  const search = async (value) => {
    if (fetchSuggestions) {
      // Use the provided fetchSuggestions function to get the list for skills
      await fetchSuggestions(value);
    } else if (fetchJobSuggestions) {
      // Use the provided fetchJobSuggestions function to get the list for job titles
      await fetchJobSuggestions(value);
    } else {
      // Default search logic
      const filteredItems = list?.filter((item) =>
        item?.toLowerCase()?.includes(value?.toLowerCase()),
      );
      setSearchList(filteredItems);
    }
  };

  useEffect(() => {
    if (!fetchSuggestions && !fetchJobSuggestions) {
      setSearchList([searchValue]);
    }
  }, [searchValue]);

  const handleSelection = (value) => {
    $("#" + idx + " .auto-complete-input").val("");
    setvalue([...initialArray, value]);
    setHide(true);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();

      handleSelection(searchValue);
    }
  };

  return (
    <div className={idx} id={idx}>
      <InputBox
        placeholder="Search..."
        className={`auto-complete-input ${classText}`}
        size={"small"}
        onChange={(e) => {
          setSearchValue(e.target.value);
          search(e.target.value); // Trigger search or fetchSuggestions
          setHide(false);
        }}
        onKeyDown={handleKeyDown}
      />
      {searchList?.length > 0 && (
        <ul
          className={
            hide
              ? "auto-complete-list hide"
              : "auto-complete-list bg-white no-list-style py-2 rounded-lg shadow-menuOptionShadow z-10 max-h-48 overflow-y-auto"
          }
        >
          {searchList
            ?.filter((element) => !initialArray?.includes(element))
            .map((item, i) => (
              <li
                key={i}
                className="match-value px-[20px] py-[10px]"
                onClick={() => {
                  $("#" + idx + " .auto-complete-input").val("");
                  setvalue([...initialArray, item]);
                  setHide(true);
                }}
              >
                {item}
              </li>
            ))}
        </ul>
      )}
    </div>
  );
}

// import React, { useEffect, useState } from "react";
// import $ from "jquery";
// import InputBox from "../../../layouts/InputBox";

// export default function AutoCompletedInput({
//   list,
//   idx,
//   setvalue,
//   initialArray,
//   classText,
// }) {
//   const [hide, setHide] = useState([]);

//   $(document).on("click", function (e) {
//     if ($(e.target).is("#" + idx + ".auto-complete-input") === false) {
//       setHide(true);
//     } else {
//       setHide(false);
//     }
//   });
//   const [tempValue, setTempValue] = useState();
//   const [searchList, setSearchList] = useState();

//   const search = (e) => {
//     const filteredItems = list?.filter((item) =>
//       item?.toLowerCase()?.includes(e?.toLowerCase()),
//     );
//     setSearchList(filteredItems);
//   };

//   const [searchValue, setSearchValue] = useState();

//   useEffect(() => {
//     setSearchList([searchValue]);
//   }, [searchValue]);

//   // useEffect(() => {
//   //     handleFilterSubmit();
//   // }, [initialArray]);

//   return (
//     <div className={idx} id={idx}>
//       <InputBox
//         placeholder="Search..."
//         className={` auto-complete-input ${classText}`}
//         size={"small"}
//         onChange={(e) => {
//           search(e.target.value);
//           setSearchValue(e.target.value);
//           setHide(false);
//         }}
//       />
//       {searchList?.length > 0 && (
//         <ul
//           className={
//             hide
//               ? "auto-complete-list hide"
//               : "auto-complete-list mt-10 bg-white  no-list-style py-[20px]  rounded-lg  shadow-menuOptionShadow z-10"
//           }
//         >
//           {searchList
//             ?.filter((element) => !initialArray?.includes(element))
//             .map((item, i) => (
//               <li
//                 key={i}
//                 className="match-value px-[20px] py-[10px]"
//                 onClick={() => {
//                   $("#" + idx + " .auto-complete-input").val("");
//                   // console.log($("#" + idx + " .auto-complete-input"))
//                   setvalue([...initialArray, item]);
//                   setTempValue();
//                   setHide(true);
//                 }}
//               >
//                 {item}
//               </li>
//             ))}
//         </ul>
//       )}
//     </div>
//   );
// }
