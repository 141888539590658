import React, { useState } from "react";
import { useEffect } from "react";
import Modal from "../../modal/Modal";
import { ScaleLoader } from "react-spinners";
const core = require("../../../lib/core");
import InputBox from "../../../layouts/InputBox";

const AverageAnswerIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 22 22"
    fill="none"
    className="inline-block mr-2"
  >
    <path
      d="M11 21.3125C16.6719 21.3125 21.3125 16.6719 21.3125 11C21.3125 5.32812 16.6719 0.6875 11 0.6875C5.32812 0.6875 0.6875 5.32812 0.6875 11C0.6875 16.6719 5.32812 21.3125 11 21.3125Z"
      fill="#FF9500"
    />
    <path
      d="M9.19531 14.2454V9.16535C9.19531 8.96535 9.25531 8.77035 9.36531 8.60535L10.7303 6.57535C10.9453 6.25035 11.4803 6.02035 11.9353 6.19035C12.4253 6.35535 12.7503 6.90535 12.6453 7.39535L12.3853 9.03035C12.3653 9.18035 12.4053 9.31535 12.4903 9.42035C12.5753 9.51535 12.7003 9.57535 12.8353 9.57535H14.8903C15.2853 9.57535 15.6253 9.73535 15.8253 10.0154C16.0153 10.2854 16.0503 10.6354 15.9253 10.9904L14.6953 14.7354C14.5403 15.3554 13.8653 15.8604 13.1953 15.8604H11.2453C10.9103 15.8604 10.4403 15.7454 10.2253 15.5304L9.58531 15.0354C9.34031 14.8504 9.19531 14.5554 9.19531 14.2454Z"
      fill="white"
    />
    <path
      d="M7.605 8.18945H7.09C6.315 8.18945 6 8.48945 6 9.22945V14.2595C6 14.9995 6.315 15.2995 7.09 15.2995H7.605C8.38 15.2995 8.695 14.9995 8.695 14.2595V9.22945C8.695 8.48945 8.38 8.18945 7.605 8.18945Z"
      fill="white"
    />
  </svg>
);
const BestAnswerIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 22 22"
    fill="none"
    className="inline-block mr-2"
  >
    <path
      d="M11 21.3125C16.6719 21.3125 21.3125 16.6719 21.3125 11C21.3125 5.32812 16.6719 0.6875 11 0.6875C5.32812 0.6875 0.6875 5.32812 0.6875 11C0.6875 16.6719 5.32812 21.3125 11 21.3125Z"
      fill="#00AC85"
    />
    <path
      d="M6.61719 10.9985L9.53562 13.917L15.3828 8.08008"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const BadAnswerIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 22 22"
    fill="none"
    className="inline-block mr-2"
  >
    <path
      d="M11 21.3125C16.6719 21.3125 21.3125 16.6719 21.3125 11C21.3125 5.32812 16.6719 0.6875 11 0.6875C5.32812 0.6875 0.6875 5.32812 0.6875 11C0.6875 16.6719 5.32812 21.3125 11 21.3125Z"
      fill="#FF475D"
    />
    <path
      d="M12.8035 8.05055V13.1305C12.8035 13.3305 12.7435 13.5255 12.6335 13.6905L11.2685 15.7205C11.0535 16.0455 10.5185 16.2755 10.0635 16.1055C9.57354 15.9405 9.24854 15.3905 9.35354 14.9005L9.61354 13.2655C9.63354 13.1155 9.59354 12.9805 9.50854 12.8755C9.42354 12.7805 9.29854 12.7205 9.16354 12.7205H7.10854C6.71354 12.7205 6.37354 12.5605 6.17354 12.2805C5.98354 12.0105 5.94854 11.6605 6.07354 11.3055L7.30354 7.56055C7.45854 6.94055 8.13354 6.43555 8.80354 6.43555H10.7535C11.0885 6.43555 11.5585 6.55055 11.7735 6.76555L12.4135 7.26055C12.6585 7.45055 12.8035 7.74055 12.8035 8.05055Z"
      fill="white"
    />
    <path
      d="M14.3947 14.105H14.9097C15.6847 14.105 15.9997 13.805 15.9997 13.065V8.04C15.9997 7.3 15.6847 7 14.9097 7H14.3947C13.6197 7 13.3047 7.3 13.3047 8.04V13.07C13.3047 13.805 13.6197 14.105 14.3947 14.105Z"
      fill="white"
    />
  </svg>
);

const UpdateIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M12 20h9"></path>
    <path d="M16.5 3.5l4 4L7 21H3v-4L16.5 3.5z"></path>
  </svg>
);

function QuestionEvaluationModal({ matchCriteria, modalDismiss, jobId }) {
  const [questions, setQuestions] = useState([]);
  const [questionsnew, setQuestionsnew] = useState([]);
  const [tempQsn, setTempQsn] = useState([]);
  const [jobSkills, setJobSkills] = useState(["Add Question"]);
  const [level, setLevel] = useState("");
  const [selectedSkill, setSelectedSkill] = useState("");

  const [newQuestion, setNewQuestion] = useState({
    name: "",
  });

  const [showAddQuestion, setShowAddQuestion] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState(""); // Track the selected level setleve local not working
  const [statusMessage, setStatusMessage] = useState("");
  const [customQuestion, setCustomQuestion] = useState([]); // State to store custom questions
  const [selectedSkillIndex, setSelectedSkillIndex] = useState(null);

  const [editingQuestionId, setEditingQuestionId] = useState(null);
  const [editingQuestionValue, setEditingQuestionValue] = useState("");

  const [editingBestAnswerId, setEditingBestAnswerId] = useState(null);
  const [bestAnswerInputValue, setBestAnswerInputValue] = useState("");

  const [filteredQuestions, setFilteredQuestions] = useState([]);

  const handleUpdateBestAnswer = (item) => {
    setEditingBestAnswerId(item._id);
    setBestAnswerInputValue(item.sample_answer?.sample_answer_high || "");
  };

  const handleSaveBestAnswer = (id) => {
    // Implement your save logic here, such as an API call
    console.log("Save best answer", id, bestAnswerInputValue);
    setEditingBestAnswerId(null);
  };

  const handleCancelBestAnswer = () => {
    setEditingBestAnswerId(null);
    setBestAnswerInputValue("");
  };

  // Function to handle edit initiation
  const handleUpdateQuestion = (item) => {
    setEditingQuestionId(item._id);
    setEditingQuestionValue(item.question); // Set the current value in the input field
  };

  // Function to handle cancel action
  const handleCancelEdit = () => {
    setEditingQuestionId(null); // Exit edit mode
    setEditingQuestionValue(""); // Reset the input field value
  };

  // Function to handle update action
  const handleSaveQuestion = (id) => {
    // Call your update API here with the updated question value
    updateCustomQuestion(id, editingQuestionValue); // Pass the updated question value to your API function
    setEditingQuestionId(null); // Exit edit mode after saving
    setEditingQuestionValue(""); // Reset the input field value
  };

  const handleSkillInputChange = (e) => {
    const inputValue = e.target.value;
    setSelectedSkill(inputValue);

    const selectedIndex = jobSkills.indexOf(inputValue);
    if (selectedIndex !== -1) {
      setSelectedSkillIndex(selectedIndex);
    } else {
      setSelectedSkillIndex(null);
    }
  };

  const deleteCustomQuestion = async (_id) => {
    try {
      let json = await core.API(
        core.API_METHODS.PUT,
        core.JOB_API.PUT_DELETE_CUSTOM_QUESTIONS + _id,
        1,
      );
      if (json?.message) {
        await getCustomQuestions();
      }
    } catch (error) {
      console.error("Failed to delete custom question:", error);
    }
  };

  const handleAddQuestion = async () => {
    setLoading(true);
    setShowAddQuestion(true);
    // const selectedSkillName = jobSkills[selectedSkillIndex];

    const selectedSkillName =
      selectedSkillIndex !== null
        ? jobSkills[selectedSkillIndex]
        : selectedSkill;

    if (!selectedSkillName || selectedSkillName.trim() === "") {
      setStatusMessage("Skill is required.");
      setLoading(false);
      return;
    }

    setStatusMessage("Saving...");
    const payload = {
      question: newQuestion.name,
      skill: selectedSkillName,
      level: selectedLevel,
      job: jobId,
    };

    console.log("Payload:", payload);

    try {
      const response = await core.API(
        core.API_METHODS.POST,
        core.JOB_API.POST_ADD_QUESTION,
        1,
        payload,
      );

      if (response.data) {
        console.log("Question added successfully:", response.data);
        setCustomQuestion((prevQuestions) => [...prevQuestions, response.data]);
        setNewQuestion({ name: "" });
        // setSelectedLevel("");
        setSelectedSkillIndex(jobSkills.indexOf(response.data.skill));
        // setSelectedSkillIndex("")
        setSelectedLevel("");
        setStatusMessage("Saved ");
      } else {
        console.error("Failed to add question:", response);
        setStatusMessage(response.message || "Failed to save.");
      }
    } catch (error) {
      console.error("Error adding question:", error);
      setStatusMessage("Failed to save.");
    } finally {
      setLoading(false);
    }
  };

  async function getCustomQuestions() {
    await core
      .API(
        core.API_METHODS.GET,
        core.JOB_API.GET_CUSTOM_QUESTIONS + jobId,
        1,
        {},
      )
      .then((json) => {
        if (json.data) {
          setCustomQuestion(json.data);
        } else {
          console.error("Failed to fetch custom questions");
        }
      })
      .catch((error) => {
        console.error("Error fetching custom questions:", error);
      });
  }

  async function getQuestionsdata(cid, status) {
    await core
      .API(core.API_METHODS.GET, core.JOB_API.GET_JOB_QUESTIONS + jobId, 1, {})
      .then((json) => {
        if (json.data) {
          setQuestions(json.data);
        } else {
        }
      });
  }

  async function getJobSkills() {
    await core
      .API(core.API_METHODS.GET, core.JOB_API.GET_JOB_SKILLS + jobId, 1, {})
      .then((json) => {
        if (json.data) {
          setJobSkills([...json.data, "Add Question"]);
          if (json?.data?.length > 0) {
            setSelectedSkill(json.data[0]);
            setSelectedSkillIndex(0);
            setLevel("EASY");
          } else {
            setShowAddQuestion(true);
          }
        } else {
          setShowAddQuestion(true);
        }
      });
  }

  const [loading, setLoading] = useState(false);

  async function get_question_by_skills(skill, level) {
    setShowAddQuestion(false);
    setTempQsn([]);
    setQuestionsnew([]);
    setLoading(true);
    await core
      .API(core.API_METHODS.POST, core.JOB_API.GET_QUESTIONS_BY_SKILLS, 1, {
        jobId: jobId,
        skill: skill,
        level: level,
      })
      .then((json) => {
        if (json.data) {
          setTempQsn(json?.data?.content);
          setQuestionsnew(json.data.content);
          setLoading(false);
        } else {
          setTempQsn([]);
          setQuestionsnew([]);
          setLoading(false);
        }
      });
  }

  useEffect(() => {
    getQuestionsdata();
    getJobSkills();
  }, []);

  useEffect(() => {
    console.log("showAddQuestion", showAddQuestion);
    if (!showAddQuestion && selectedSkill != "Add Question") {
      get_question_by_skills(selectedSkill, level);
    }
  }, [level, selectedSkill, showAddQuestion]);

  const levels = [
    { name: "Easy", id: "EASY" },
    { name: "Medium", id: "MEDIUM" },
    { name: "Hard", id: "HARD" },
  ];

  const [criteria, setCriteria] = useState(0);

  const getLevel = (item) => {
    if (item?.level?.includes("HARD")) {
      return "#FF475D";
    } else if (item?.level?.includes("EASY")) {
      return "#00AC84";
    } else return "#FF9500";
  };

  const filterQusn_by_level = (level) => {
    let temp = [];
    if (level === "") {
      setQuestionsnew(tempQsn);
    } else {
      tempQsn.map((item) => {
        if (item?.level?.toLowerCase() === level?.toLowerCase()) {
          temp.push(item);
        }
      });
      setQuestionsnew(temp);
    }
  };

  useEffect(() => {
    if (customQuestion.length > 0) {
      const filtered = level
        ? customQuestion.filter(
            (question) => question.level?.toLowerCase() === level.toLowerCase(),
          )
        : customQuestion;

      setFilteredQuestions(filtered);
    }
  }, [level, customQuestion]);

  useEffect(() => {
    filterQusn_by_level(level);
  }, [level]);

  const onSkillChange = async (skill, index) => {
    if (skill === "Add Question") {
      setTempQsn([]);
      setQuestionsnew([]);
      setCriteria(index);
      setShowAddQuestion(true);
      await getCustomQuestions();
    } else {
      setCriteria(index);
      setSelectedSkill(skill);
      setShowAddQuestion(false);
    }
  };

  return (
    <Modal
      heading={"Interview Q/A"}
      modalDismiss={modalDismiss}
      className={"md:max-w-[75%] scrollbar-hide"}
      noBtns={true}
    >
      <div className="flex flex-wrap gap-2">
        {jobSkills?.map((item, index) => (
          <button
            key={"criteria" + index}
            className={`border border-gray-300 py-2 px-4 rounded-lg text-gray-800 ${
              criteria === index ? "bg-gray-300" : "bg-white"
            }`}
            onClick={() => onSkillChange(item, index)}
          >
            {item}
          </button>
        ))}
      </div>
      <div className="flex flex-wrap mt-2 mb-4 gap-2.5">
        {levels?.map((lvl) => (
          <button
            key={lvl.id}
            className={` border border-gray-300 py-2 px-4 rounded-lg text-gray-800 ${
              level == lvl.id ? "bg-gray-300" : "bg-white"
            }`}
            onClick={() => {
              if (level === lvl.id) {
                setLevel("");
                setQuestionsnew(tempQsn);
              } else {
                setLevel(lvl.id);
                filterQusn_by_level(lvl.id);
              }
            }}
          >
            {lvl.name}
          </button>
        ))}
      </div>
      {showAddQuestion && (
        <>
          <div className="add-question-section p-4 border border-gray-300 rounded-lg mt-4">
            <h4 className="text-lg font-bold mb-4">Add new question</h4>

            {/* Question Name Input */}
            <div className="form-group mb-4 ">
              <label
                htmlFor="questionInput"
                className="block text-gray-800 font-medium mb-1 required"
              >
                Question:
              </label>

              <textarea
                type="text"
                placeholder="Enter your question here..."
                className="border border-gray-300 rounded-lg py-2 px-4 w-full mb-4 placeholder-gray-800 min-h-[100px]"
                value={newQuestion.name}
                onChange={(e) =>
                  setNewQuestion({ ...newQuestion, name: e.target.value })
                }
              />
            </div>

            <div className="form-group flex items-start gap-8">
              <div className="w-1/2 mt-[-29px]">
                <label className="required" htmlFor="skillsDropdown">
                  Skill
                </label>
                {/* <select
                  id="skillsDropdown"
                  className="border border-gray-300 py-2 px-4 rounded-lg mb-4 w-full"
                  value={selectedSkillIndex || ""}
                  onChange={(e) => {
                    setSelectedSkillIndex(
                      e.target.value === "" ? "" : parseInt(e.target.value),
                    );
                  }}
                >
                  <option value="" hidden>
                    Select Skill
                  </option>
                  {jobSkills
                    .filter((skill) => skill !== "Add Question")
                    .map((item, index) => (
                      <option key={index} value={index}>
                        {item}
                      </option>
                    ))}
                </select> */}
                <div className="relative">
                  <input
                    list="skills"
                    id="skillsInput"
                    className="border border-gray-300 placeholder-gray-800 py-2 px-4 rounded-lg mb-4 w-full text-black"
                    value={selectedSkill}
                    onChange={handleSkillInputChange}
                    placeholder="Select or type a skill"
                  />
                  <datalist id="skills">
                    <option value="" disabled hidden>
                      Select Skill
                    </option>
                    {jobSkills
                      .filter((skill) => skill !== "Add Question")
                      .map((skill, index) => (
                        <option key={index} value={skill} />
                      ))}
                  </datalist>
                </div>
              </div>

              <div className="w-1/2 mt-[-29px]">
                <label htmlFor="levelsDropdown">Level</label>
                <select
                  id="levelsDropdown"
                  className="border border-gray-300 py-2 px-4 rounded-lg mb-4 w-full"
                  value={selectedLevel || ""}
                  onChange={(e) => setSelectedLevel(e.target.value)}
                >
                  <option value="" disabled hidden>
                    Select Level
                  </option>
                  <option value="EASY">Easy</option>
                  <option value="MEDIUM">Medium</option>
                  <option value="HARD">Hard</option>
                </select>
              </div>
            </div>

            {console.log("newQuestion", newQuestion, selectedSkillIndex)}
            {/* Button to Save or Submit the Question */}
            <button
              className={`py-2 px-4 rounded-lg text-white ${newQuestion.name && !loading ? "bg-blue-700" : "bg-blue-500 cursor-not-allowed mt-[-6px]"}`}
              disabled={!newQuestion.name || loading}
              onClick={handleAddQuestion}
            >
              Save
            </button>
            {statusMessage && (
              <p className="text-black ml-20 mt-[-28px]">{statusMessage}</p>
            )}
          </div>

          {filteredQuestions?.length > 0 ? (
            <>
              <p className="font-semibold text-base mb-4 mt-2">
                Custom questions
              </p>
              {filteredQuestions.map((item, index) => (
                <div
                  className="w-full border-gray-300 border-b last:border-0 flex justify-between gap-8"
                  key={index}
                >
                  <span className="max-w-[5%] text-gray-800 text-base not-italic font-semibold">{`${index + 1}.`}</span>

                  <div className="grow group">
                    {editingQuestionId === item._id ? (
                      // If this question is being edited, show input field with Update/Cancel buttons
                      <div className="relative">
                        <input
                          type="text"
                          value={editingQuestionValue}
                          onChange={(e) =>
                            setEditingQuestionValue(e.target.value)
                          }
                          className="w-full border border-gray-300 rounded-lg py-1 px-1.5"
                        />
                        <button
                          className="m-1 bg-blue-800 text-white py-1 px-4 rounded-full"
                          onClick={() => handleSaveQuestion(item._id)}
                        >
                          Update
                        </button>
                        <button
                          className="m-1 bg-gray-500 text-white py-1 px-4 rounded-full"
                          onClick={handleCancelEdit}
                        >
                          Cancel
                        </button>
                      </div>
                    ) : (
                      // Otherwise, just show the question text with the Update icon
                      <div className="relative">
                        <span className="text-gray-800 text-base not-italic font-normal">
                          {item.question}
                        </span>

                        {/* <button
                      type="button"
                      className="ml-2"
                      aria-label="Update question"
                      onClick={() => handleUpdateQuestion(item)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M12 20h9"></path>
                        <path d="M16.5 3.5l4 4L7 21H3v-4L16.5 3.5z"></path>
                      </svg>
                    </button> */}

                        <button
                          type="button"
                          className="absolute right-0 top-1/2 transform -translate-y-1/2"
                          aria-label="Delete question"
                          onClick={() => {
                            console.log(item._id);
                            deleteCustomQuestion(item._id);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="red"
                            className="w-5 h-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                            />
                          </svg>
                        </button>
                      </div>
                    )}

                    {/* Sample Answers and other content remain the same */}
                    <div className="w-full flex flex-wrap gap-2 my-2">
                      <span className="py-1 px-1.5">
                        <span
                          className="inline-block w-2 h-2 rounded-lg mr-2"
                          style={{
                            backgroundColor: getLevel(item),
                            textTransform: "lowercase",
                          }}
                        />
                        {item?.level}
                      </span>
                      {item?.evaluation_criteria &&
                      item.evaluation_criteria.length > 0 ? (
                        item.evaluation_criteria.map((data, criteriaIndex) => (
                          <span
                            className="border border-gray-300 rounded-lg py-1 px-1.5"
                            key={criteriaIndex}
                          >
                            {data}
                          </span>
                        ))
                      ) : (
                        <span className="border border-gray-300 rounded-lg py-1 px-1.5">
                          Processing
                        </span>
                      )}

                      {/* Always display skill */}
                      <span className="border border-gray-300 rounded-lg py-1 px-1.5">
                        {item?.skill}
                      </span>
                    </div>

                    {/* Sample Answers */}
                    {item?.sample_answer && (
                      <div className="w-full relative rounded-lg bg-gray-200 mb-5">
                        {/* Green Header Section */}
                        <div className="w-full flex flex-row justify-between bg-green-100 px-2 rounded-t-lg py-1.5 text-black text-base font-medium not-italic">
                          <span className="relative">
                            {BestAnswerIcon}
                            <span>Best answer</span>
                            {editingBestAnswerId === item._id ? null : (
                              // Show update icon only when not editing
                              <span className="absolute top-0.5">
                                <button
                                  type="button"
                                  className="ml-2"
                                  aria-label="Update best answer"
                                  // onClick={() => handleUpdateBestAnswer(item)}
                                >
                                  {/* {UpdateIcon} */}
                                </button>
                              </span>
                            )}
                          </span>
                          <span>
                            Score
                            <span className="bg-white shadow px-2 py-1 ml-2 rounded-lg">
                              7-10
                            </span>
                          </span>
                        </div>

                        {/* Content Section */}
                        <div className="w-full p-4 text-sm text-gray-800">
                          {editingBestAnswerId === item._id ? (
                            // Show input field value instead of static text when editing
                            <span>{bestAnswerInputValue}</span>
                          ) : (
                            // Show static text when not editing
                            <span>
                              {item?.sample_answer?.sample_answer_high}
                            </span>
                          )}
                        </div>

                        {/* Additional Explanation */}
                        {!editingBestAnswerId && (
                          <div className="w-full bg-white rounded-lg mt-2 p-2 flow-root italic">
                            <h4 className="w-full mb-2">
                              Score explanation :-
                            </h4>
                            {item?.sample_answer?.high_answer_reason}
                          </div>
                        )}
                      </div>
                    )}

                    {/* Editing Controls for Best Answer */}
                    {editingBestAnswerId === item._id && (
                      <div className="w-full relative mt-4">
                        <InputBox
                          multiline
                          rows={10}
                          type="text"
                          value={bestAnswerInputValue}
                          onChange={(e) =>
                            setBestAnswerInputValue(e.target.value)
                          }
                          className="w-full border border-gray-300 rounded-lg py-1 px-1.5 mb-2"
                        />
                        <button
                          className="m-1 bg-blue-800 text-white py-1 px-4 rounded-full"
                          onClick={() => handleSaveBestAnswer(item._id)}
                        >
                          Update
                        </button>
                        <button
                          className="m-1 bg-gray-500 text-white py-1 px-4 rounded-full"
                          onClick={handleCancelBestAnswer}
                        >
                          Cancel
                        </button>
                      </div>
                    )}

                    {item?.sample_answer && (
                      <div className="w-full relative rounded-lg bg-gray-200 mb-5">
                        <div className="w-full flex flex-row justify-between bg-orange-100 px-2 rounded-t-lg py-1.5 text-black text-base font-medium not-italic">
                          <span>
                            {AverageAnswerIcon}
                            <span>Average answer</span>
                          </span>
                          <span>
                            Score
                            <span className="bg-white shadow px-2 py-1 ml-2 rounded-lg">
                              4-6
                            </span>
                          </span>
                        </div>
                        <div className="w-full p-4 text-sm text-gray-800">
                          <span>{item?.sample_answer?.sample_answer_mid} </span>
                          <div className="w-full bg-white rounded-lg mt-2 p-2 flow-root italic">
                            <h4 className="w-full mb-2">
                              Score explanation :-
                            </h4>
                            {item?.sample_answer?.mid_answer_reason}
                          </div>
                        </div>
                      </div>
                    )}

                    {item?.sample_answer && (
                      <div className="w-full relative rounded-lg bg-gray-200 mb-5">
                        <div className="w-full flex flex-row justify-between bg-red-100 px-2 rounded-t-lg py-1.5 text-black text-base font-medium not-italic">
                          <span>
                            {BadAnswerIcon}
                            <span>Bad answer</span>
                          </span>
                          <span>
                            Score
                            <span className="bg-white shadow px-2 py-1 ml-2 rounded-lg">
                              0-3
                            </span>
                          </span>
                        </div>
                        <div className="w-full p-4 text-sm text-gray-800">
                          <span>{item?.sample_answer?.sample_answer_low} </span>
                          <div className="w-full bg-white rounded-lg mt-2 p-2 flow-root italic">
                            <h4 className="w-full mb-2">
                              Score explanation :-
                            </h4>
                            {item?.sample_answer?.low_answer_reason}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </>
          ) : (
            <p className="text-gray-600">
              No custom questions available for this job.
            </p>
          )}
        </>
      )}

      {!showAddQuestion && questionsnew?.length > 0 ? (
        <>
          {questionsnew?.map((item, index) => (
            <>
              <div
                className="w-full border-gray-300 border-b last:border-0 flex justify-between gap-8"
                key={index}
              >
                <span className="max-w-[5%] text-gray-800 text-base not-italic font-semibold">{`${
                  index + 1
                }.`}</span>
                <div className="grow">
                  <span className="text-gray-800 text-base not-italic font-normal">
                    {item?.question}
                  </span>
                  <div className="w-full flex flex-wrap gap-2 my-2">
                    <span className="py-1 px-1.5">
                      <span
                        className="inline-block w-2 h-2 rounded-lg mr-2  "
                        style={{
                          backgroundColor: getLevel(item),
                          textTransform: "lowercase",
                        }}
                      />
                      {item?.level}
                    </span>
                    {item?.evaluation_criteria?.map((data, index) => (
                      <span
                        className="border border-gray-300 rounded-lg py-1 px-1.5"
                        key={index}
                      >
                        {data}
                      </span>
                    ))}
                    {/* {item?.topic_tag?.map((data, idx) => (
                  <span key={idx}
                    className="border border-gray-300 rounded-lg py-1 px-1.5"
                  >
                    {data}
                  </span>
                ))} */}
                  </div>
                  <div className="w-full">
                    {item?.sample_answer && (
                      <>
                        <h3 className="w-full sample-answer-tag my-3">
                          Sample answer
                        </h3>
                        <div className="w-full relative rounded-lg bg-gray-200 mb-5">
                          <div className="w-full flex flex-row justify-between bg-green-100 px-2 rounded-t-lg py-1.5 text-black text-base font-medium not-italic">
                            <span>
                              {BestAnswerIcon}
                              <span>Best answer</span>
                            </span>
                            <span>
                              Score
                              <span className="bg-white shadow px-2 py-1 ml-2 rounded-lg">
                                7-10
                              </span>
                            </span>
                          </div>
                          <div className="w-full p-4 text-sm text-gray-800">
                            <span>
                              {item?.sample_answer?.sample_answer_high}{" "}
                            </span>
                            <div className="w-full bg-white rounded-lg mt-2 p-2 flow-root italic">
                              <h4 className="w-full mb-2">
                                Score explanation :-
                              </h4>
                              {item?.sample_answer?.high_answer_reason}
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {item?.sample_answer && (
                      <div className="w-full relative rounded-lg bg-gray-200 mb-5">
                        <div className="w-full flex flex-row justify-between bg-orange-100 px-2 rounded-t-lg py-1.5 text-black text-base font-medium not-italic">
                          <span>
                            {AverageAnswerIcon}
                            <span>Average answer</span>
                          </span>
                          <span>
                            Score
                            <span className="bg-white shadow px-2 py-1 ml-2 rounded-lg">
                              4-6
                            </span>
                          </span>
                        </div>
                        <div className="w-full p-4 text-sm text-gray-800">
                          <span>{item?.sample_answer?.sample_answer_mid} </span>
                          <div className="w-full bg-white rounded-lg mt-2 p-2 flow-root italic">
                            <h4 className="w-full mb-2">
                              Score explanation :-
                            </h4>
                            {item?.sample_answer?.mid_answer_reason}
                          </div>
                        </div>
                      </div>
                    )}
                    {item?.sample_answer && (
                      <div className="w-full relative rounded-lg bg-gray-200 mb-5">
                        <div className="w-full flex flex-row justify-between bg-red-100 px-2 rounded-t-lg py-1.5 text-black text-base font-medium not-italic">
                          <span>
                            {BadAnswerIcon}
                            <span>Bad answer</span>
                          </span>
                          <span>
                            Score
                            <span className="bg-white shadow px-2 py-1 ml-2 rounded-lg">
                              0-3
                            </span>
                          </span>
                        </div>
                        <div className="w-full p-4 text-sm text-gray-800">
                          <span>{item?.sample_answer?.sample_answer_low} </span>
                          <div className="w-full bg-white rounded-lg mt-2 p-2 flow-root italic">
                            <h4 className="w-full mb-2">
                              Score explanation :-
                            </h4>
                            {item?.sample_answer?.low_answer_reason}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* <div
              className="col-xs-1 no-padding right-side-icon"
              style={{
                width: "5%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {index === showAns ? (
                <span
                  onClick={(e) => setShowAns(null)}
                  style={{ cursor: "pointer" }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5 14.4844L12 8.99995L6.5 14.4844"
                      stroke="#252E4B"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              ) : (
                <span
                  onClick={(e) => setShowAns(index)}
                  style={{ cursor: "pointer" }}
                >
                  
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.5 9L12 14.4844L17.5 9"
                      stroke="#252E4B"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              )}
            </div> */}
              </div>
            </>
          ))}
        </>
      ) : (
        !showAddQuestion && (
          <div className="w-full text-center text-gray-600 text-xl font-semibold mt-[100px] mb-[100px]">
            {loading ? (
              <div className="flex items-center justify-center">
                <ScaleLoader color="#1369e9" loading={loading} />
              </div>
            ) : (
              <>No questions available for this skill</>
            )}
          </div>
        )
      )}
    </Modal>
  );
}

export default QuestionEvaluationModal;
