import React, { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import JobSidebar from "../../components/all/JobSidebar";
import HeaderNew from "../../components/all/header/HeaderNew";
import TemplateList from "../../components/recruiter/recruiterTemplates/TemplateList";
const core = require("../../lib/core");

export const JobTemplateScreen = () => {
  const nav = useNavigate();
  const loc = useLocation();
  const { user } = useContext(core.UserContext);
  const [templates, setTemplates] = useState([]);
  const [showTabsItem, setShowTabsItem] = useState("");
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1); // Page state for pagination
  const [loadingMore, setLoadingMore] = useState(false); // Loading state for pagination

  useEffect(() => {
    console.log("showTabsItem", showTabsItem);
    if (showTabsItem === "Personal") {
      getAllData(false);
    } else if (showTabsItem === "Shared") {
      getAllData("shared");
    } else if (showTabsItem === "Archived") {
      getAllData("archived");
    } else if (showTabsItem === "Organization") {
      getAllData("organization");
    }
  }, [showTabsItem]);

  useEffect(() => {
    if (
      loc.pathname.split("/").filter((path) => path !== "")?.length === 2 &&
      templates?.length > 0
    ) {
      nav(templates[0]?._id);
    }
  }, [loc, templates]);

  const getAllData = async (type) => {
    setLoading(true);

    let endpoint = core.TEMPLATE_API.GET_JOB_TEMPLATE_LIST_PERSONAL;

    if (type === "shared" || type === "Shared") {
      endpoint =
        core.TEMPLATE_API.GET_JOB_TEMPLATE_LIST_PERSONAL +
        "?shared=true&page=1";
    } else if (type === "archived" || type === "Archived") {
      endpoint =
        core.TEMPLATE_API.GET_JOB_TEMPLATE_LIST_PERSONAL +
        "?archived=true&page=1";
    } else if (type === "organization" || type === "Organization") {
      endpoint =
        core.TEMPLATE_API.GET_JOB_TEMPLATE_LIST_PERSONAL +
        "?organization=true&page=1";
    } else {
      endpoint =
        core.TEMPLATE_API.GET_JOB_TEMPLATE_LIST_PERSONAL +
        "?organization=false&page=1";
    }

    const list = await core.API(core.API_METHODS.GET, endpoint, 1);
    if (list?.data) {
      setTemplates(list?.data);
      setPage(1);
    }
    setLoading(false);
  };

  const fetchMoreData = async () => {
    setLoadingMore(true);
    // Dummy function to simulate fetching more templates
    const moreTemplates = templates.slice(0, 20); // Just duplicating the first 20 templates for demonstration
    setTemplates((prev) => [...prev, ...moreTemplates]);
    setPage((prev) => prev + 1);
    setLoadingMore(false);
  };

  return (
    <div className="w-screen h-screen p-0 overflow-hidden" id="template_page">
      <div className="flex flex-row w-screen h-screen left-0 relative top-0">
        <JobSidebar
          active="Templates"
          CandidateFilter={
            <TemplateList
              setTemplates={setTemplates}
              templates={templates}
              showTabsItem={showTabsItem}
              setShowTabsItem={setShowTabsItem}
              loading={loading}
              fetchMoreData={fetchMoreData} // Pass fetchMoreData function
              loadingMore={loadingMore} // Pass loadingMore state
            />
          }
        />
        <div className="w-[calc(100%-350px)] grow h-screen flex flex-col gap-4 pb-4">
          <HeaderNew />
          <main className="w-[calc(100%-16px)] relative grow flex flex-col gap-4 mx-2 p-4 rounded-lg bg-[#F3F5F9] overflow-y-scroll scrollbar-candidate">
            <Outlet
              context={{
                reloadList: getAllData,
                changeTabs: setShowTabsItem,
                showTabsItem: showTabsItem,
              }}
            />
          </main>
        </div>
      </div>
    </div>
  );
};
