import React, { useState, useEffect, useRef } from "react";
import Draggable from "react-draggable";
import { ScaleLoader } from "react-spinners";
import { LuThumbsDown, LuThumbsUp } from "react-icons/lu";
import { IoMdThumbsDown, IoMdThumbsUp } from "react-icons/io";
const DraggableActions = ({
  currentIndex,
  setCurrentIndex,
  candidatesLength,
  previousCandidate,
  nextCandidate,
  setShowReviewModal,
  closeReview,
  pipelineStages,
  currentCandidate,
  setChatDetail,
  setShowReject,
  updateStatus,
  currentPage,
  totalPages,
  onPageChange,
  activeCandidates,
  isPageLoading,
  isSourceCandidate,
  setCandidateDetail,
  atsloading,
  setShowModal,
  resources,
}) => {
  const [position, setPosition] = useState(() => ({
    x: window.innerWidth / 2 - 280,
    y: window.innerHeight - 120,
  }));
  const [showStages, setShowStages] = useState(false);
  const nodeRef = useRef(null);
  const [dropdownPosition, setDropdownPosition] = useState("top");

  const calculateDropdownPosition = () => {
    if (nodeRef.current) {
      const rect = nodeRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - rect.bottom;
      const spaceAbove = rect.top;
      const dropdownHeight = 200;

      if (spaceBelow >= dropdownHeight) {
        setDropdownPosition("bottom");
      } else if (spaceAbove >= dropdownHeight) {
        setDropdownPosition("top");
      } else {
        setDropdownPosition("bottom");
      }
    }
  };
  useEffect(() => {
    if (showStages) {
      calculateDropdownPosition();
    }
  }, [position]);
  useEffect(() => {
    const handleResize = () => {
      setPosition({
        x: window.innerWidth / 2 - 280,
        y: window.innerHeight - 120,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages && !isPageLoading) {
      onPageChange({ selected: newPage - 1 });
    }
  };
  const handleNextCandidate = () => {
    if (isPageLoading) return;
    const nextIndex = currentIndex + 1;
    if (nextIndex % 20 === 0 && nextIndex < activeCandidates) {
      setCurrentIndex(0);
      onPageChange({ selected: currentPage });
    } else if (nextIndex >= activeCandidates) {
      return;
    }
    nextCandidate();
  };

  const handlePreviousCandidate = async () => {
    if (isPageLoading) return;
    const prevOverallIndex = currentIndex + (currentPage - 1) * 20 - 1;

    if (prevOverallIndex < 0) {
      return;
    }

    if (currentIndex === 0 && currentPage > 1) {
      await onPageChange({ selected: currentPage - 2 });
      // currentIndex = candidatesLength - 1;
      setCurrentIndex(19);
    } else {
      previousCandidate();
    }
  };
  return (
    <Draggable
      nodeRef={nodeRef}
      position={position}
      onDrag={(e, data) => {
        setPosition({ x: data.x, y: data.y });
        if (showStages) {
          calculateDropdownPosition();
        }
      }}
      onStop={(e, data) => {
        setPosition({ x: data.x, y: data.y });
        if (showStages) {
          calculateDropdownPosition();
        }
      }}
      bounds="parent"
      handle=".handle"
    >
      <div
        ref={nodeRef}
        className={`fixed handle cursor-move bg-white rounded-lg shadow-2xl flex flex-col justify-center items-center p-2 ${isSourceCandidate ? "w-[40%]" : "w-[45%]"} z-[10]`}
        style={{ zIndex: 9999 }}
      >
        <div className="flex justify-center items-center space-x-2 w-full">
          <button
            onClick={handlePreviousCandidate}
            disabled={currentIndex + (currentPage - 1) * 20 === 0}
            className="btn-sec px-2 py-1 w-8 h-8 text-gray-700 disabled:opacity-50 bg-blue-100 hover:bg-blue-200"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M15 18l-6-6 6-6" />
            </svg>
          </button>
          <span className="text-sm handle cursor-move font-semibold">
            {currentIndex + 1 + (currentPage - 1) * 20} / {activeCandidates}
          </span>
          {/* <button
            onClick={handleNextCandidate}
            disabled={
              currentIndex + (currentPage - 1) * 20 === activeCandidates - 1
            }
            className="btn-sec px-2 py-1 w-8 h-8 text-gray-700 disabled:opacity-50"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M9 18l6-6-6-6" />
            </svg>
          </button> */}
          <button
            onClick={(e) => setShowReviewModal("send-notes")}
            className="btn-sec px-2 py-1 w-8 h-8 text-gray-700 disabled:opacity-50"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-full h-full"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
              />
            </svg>
          </button>

          {/* {resources?.filter((item) => item === "Candidate_create")?.length >
            0 && (
            <div className=" bg-white py-1 px-3 z-[9]  flex items-center ">
              <span
                className="mr-2 cursor-pointer w-[30px] h-[30px] flex items-center justify-center rounded-[50%] hover:bg-[#F2F4F8]"
                onClick={() => {
                  setCandidateDetail({
                    name: currentCandidate?.notes,
                    id: currentCandidate?._id,
                    vote: true,
                  });
                  setShowModal("add-notes-modal");
                }}
              >
                {currentCandidate?.thumbs_up === 1 ? (
                  <IoMdThumbsUp
                    color="#5F6989"
                    data-tooltip-id="my-tooltip-4"
                    data-tooltip-content={"like"}
                    className="candidate-like-icon w-6 h-6"
                  />
                ) : (
                  <LuThumbsUp
                    color="#5F6989"
                    data-tooltip-id="my-tooltip-4"
                    data-tooltip-content={"like"}
                    className="candidate-like-icon w-5 h-5"
                  />
                )}
              </span>
              <span
                className="w-[30px] cursor-pointer h-[30px] flex items-center justify-center rounded-[50%] hover:bg-[#F2F4F8]"
                onClick={() => {
                  setCandidateDetail({
                    name: currentCandidate?.notes,
                    id: currentCandidate?._id,
                    vote: false,
                  });
                  setShowModal("add-notes-modal");
                }}
              >
                {currentCandidate?.thumbs_up === 2 ? (
                  <IoMdThumbsDown
                    color="#5F6989"
                    data-tooltip-id="my-tooltip-4"
                    data-tooltip-content={"Dislike"}
                    className="candidate-unlinke-icon w-6 h-6"
                  />
                ) : (
                  <LuThumbsDown
                    color="#5F6989"
                    data-tooltip-id="my-tooltip-4"
                    data-tooltip-content={"Dislike"}
                    className="candidate-unlinke-icon w-5 h-5"
                  />
                )}
              </span>
            </div>
          )} */}
          <div className="flex space-x-2 items-center my-4 ">
            <div className="relative">
              <div className="flex items-center gap-2">
                {isSourceCandidate ? (
                  <button
                    className={`flex items-center rounded-lg border h-8 border-gray-500 ${
                      currentCandidate?.pushed_as_prospect
                        ? "bg-bg1 "
                        : "bg-white border-button"
                    } py-2 px-2.5 justify-center min-w-[140px]`}
                    onClick={() => {
                      setCandidateDetail({
                        id: currentCandidate?._id,
                      });
                      setShowModal("select-stage");
                    }}
                    data-tooltip-id="my-tooltip-4"
                    data-tooltip-content={"Convert prospect to candidate"}
                  >
                    <span className="text-gray-800 text-sm font-normal w-[170px] truncate not-italic hover:text-blue-900 focus:text-blue-900">
                      {currentCandidate?._id === atsloading ? (
                        <ScaleLoader
                          height={14}
                          color="#1369E9"
                          loading={true}
                        />
                      ) : (
                        "Convert to candidate"
                      )}
                    </span>
                  </button>
                ) : (
                  <button
                    onClick={() => setShowStages(!showStages)}
                    className="border-button flex items-center rounded-lg border h-8 border-gray-500 bg-white py-2 px-2.5 justify-center max-w-[160px] min-w-[140px]"
                  >
                    <span className="text-gray-800 text-sm font-normal not-italic hover:text-blue-900 focus:text-blue-900 max-w-[140px] truncate">
                      {currentCandidate?.current_stage}
                    </span>
                    <span className="ml-8px">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M6.71838 8.30751L6.71753 8.30657L5.18845 6.62649C5.17672 6.6136 5.17066 6.60014 5.16811 6.58407C5.1652 6.56574 5.16722 6.54403 5.17472 6.5241C5.17827 6.51464 5.18239 6.50742 5.18595 6.50247C5.18662 6.50154 5.18724 6.50072 5.18782 6.5L8.14833 6.5L10.8114 6.5C10.8151 6.50456 10.8205 6.51266 10.8253 6.52534C10.8331 6.54588 10.8351 6.56776 10.8323 6.58575C10.8299 6.60139 10.8241 6.61416 10.8129 6.62649L8.34541 9.33765C8.14851 9.55399 7.84854 9.55412 7.65152 9.33803C7.6514 9.33791 7.65128 9.33778 7.65116 9.33765L6.71838 8.30751Z"
                          fill="#252E4B"
                          stroke="#252E4B"
                        />
                      </svg>
                    </span>
                  </button>
                )}
              </div>
              {showStages && !isSourceCandidate && (
                <div
                  className={`absolute w-[160px] ${
                    dropdownPosition === "top" ? "bottom-[40px]" : "top-[40px]"
                  } right-0 bg-white rounded-lg p-1 shadow-menuOptionShadow z-10`}
                >
                  <ul>
                    {pipelineStages
                      ?.filter(
                        (item) =>
                          item.stage !== currentCandidate.current_stage &&
                          item.stage !== "Rejected",
                      )
                      ?.map((item, index) => (
                        <li
                          key={index}
                          className="p-2 text-gray-800 text-sm font-normal not-italic rounded-lg cursor-pointer hover:bg-bg1"
                          onClick={() => {
                            updateStatus(
                              currentCandidate?._id,
                              item?.stage,
                              "",
                            );
                            setShowStages(false);
                          }}
                        >
                          {item?.stage}
                        </li>
                      ))}
                  </ul>
                </div>
              )}
            </div>
            {!isSourceCandidate && (
              <div className="flex space-x-2">
                {currentCandidate?.current_stage !== "Rejected" && (
                  <button
                    className={`px-6 py-2 h-8 rounded flex items-center gap-2 ${
                      currentCandidate?.current_stage === "Application Review"
                        ? "bg-green-200 text-green-900 hover:bg-green-300"
                        : "bg-green-200 text-green-900 cursor-not-allowed opacity-70"
                    }`}
                    disabled={
                      currentCandidate?.current_stage != "Application Review"
                    }
                    onClick={() => {
                      if (
                        currentCandidate?.current_stage === "Application Review"
                      ) {
                        const nextStageIndex =
                          pipelineStages.findIndex(
                            (stage) =>
                              stage.stage === currentCandidate.current_stage,
                          ) + 1;
                        if (nextStageIndex < pipelineStages.length) {
                          updateStatus(
                            currentCandidate?._id,
                            pipelineStages[nextStageIndex].stage,
                            "",
                          );
                        }
                      }
                    }}
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <polyline points="20 6 9 17 4 12"></polyline>
                      </svg>
                    </span>
                    {currentCandidate?.current_stage === "Application Review"
                      ? "Accept"
                      : "Accepted"}
                  </button>
                )}
                {currentCandidate?.current_stage !== "Rejected" && (
                  <button
                    className="px-6 py-2 bg-red-200 h-8 text-red-900 rounded hover:bg-red-300 flex items-center gap-2"
                    onClick={() => {
                      setChatDetail({
                        id: currentCandidate?._id,
                        email: currentCandidate?.applicant?.email,
                        name: currentCandidate?.applicant?.first_name,
                      });
                      setShowReject("Show_reject_modal");
                    }}
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 2048 2048"
                      >
                        <path
                          fill="currentColor"
                          d="m1115 1024l690 691l-90 90l-691-690l-691 690l-90-90l690-691l-690-691l90-90l691 690l691-690l90 90z"
                        />
                      </svg>
                    </span>
                    Reject
                  </button>
                )}
              </div>
            )}
          </div>

          {/* <button
            onClick={closeReview}
            className="btn-sec px-2 py-1 w-8 h-8 text-gray-700"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M18 6L6 18M6 6l12 12" />
            </svg>
          </button> */}
          <button
            onClick={handleNextCandidate}
            disabled={
              currentIndex + (currentPage - 1) * 20 === activeCandidates - 1
            }
            className="btn-sec px-2 py-1 w-8 h-8 text-gray-700 disabled:opacity-50 bg-blue-100 hover:bg-blue-200"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M9 18l6-6-6-6" />
            </svg>
          </button>
        </div>
      </div>
    </Draggable>
  );
};

export default DraggableActions;
