import React, { useEffect, useState } from "react";
import { convertToHTML } from "draft-convert";
import {
  convertFromHTML,
  ContentState,
  EditorState,
  convertToRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Modal from "../../modal/Modal";
import { useSelector } from "react-redux";
import SelectAndSearch from "../../../layouts/SelectAndSearch";
import { useContext } from "react";
import SearchUser from "../user/SearchUser";
import Attachments from "../../../layouts/Attachments";
import InputBox from "../../../layouts/InputBox";
import EmailScheduler from "./EmailScheduler";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";

const core = require("../../../lib/core");

function SendEmail({ chatDetail, companydata, modalDismiss }) {
  const [editorStateDesc, setEditorStateDesc] = useState(() =>
    EditorState.createEmpty(),
  );
  const [editorValue, setEditorValue] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [from, setFrom] = useState([]);
  const [to, setTo] = useState([]);
  const [subject, setSubject] = useState([]);
  const { user } = useContext(core.UserContext);

  const { position, jobId } = useSelector((state) => {
    return {
      position: state?.JobExpandSlice?.profile?.position,
      jobId: state?.JobExpandSlice?.profile?.id,
    };
  });

  useEffect(() => {
    if (chatDetail?.email?.length > 0) {
      setTo(chatDetail?.email[0]);
    }
  }, [chatDetail]);

  const [emailSuccess, setEmailSuccess] = useState(false);

  useEffect(() => {
    setEditorValue(
      draftToHtml(convertToRaw(editorStateDesc?.getCurrentContent())),
    );
    console.log(
      draftToHtml(convertToRaw(editorStateDesc?.getCurrentContent())),
    );
  }, [editorStateDesc]);

  const [sendText, setSendingText] = useState("Send email");
  const [attachments, setAttachments] = useState([]);
  const [attachmentsUrl, setAttachmentsUrl] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(user.email ?? "");

  console.log(user);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});

  async function sendEmail(attachmentlinks = [], executeOn) {
    try {
      await core
        .API(
          core.API_METHODS.POST,
          core.RECRUITER_API.POST_EMAIL_CANDIDATE,
          1,
          {
            to: to,
            cc: selectedOptions?.map((option) => option?.email),
            body: editorValue,
            subject: subject,
            matchprofile: chatDetail?.matchId,
            applicant: chatDetail?._id,
            job: jobId,
            name: chatDetail?.first_name + " " + chatDetail?.last_name ?? "",
            from: selectedEmail ?? "",
            s3AttachmentUrls: [
              ...(attachmentlinks || []),
              ...(attachmentsUrl || []),
            ],
            execute_on: executeOn,
          },
        )
        .then((json) => {
          setEmailSuccess(true);
          setSubject();
          setSendingText("Send email");
        });
    } catch (error) {
      console.error("Error sending email:", error);
      setLoading(false);
      setMessage({
        type: "error",
        message: "Error sending email",
      });
    }
  }

  const handleSubmit = async (executeOn) => {
    setLoading(true);
    if (
      !(
        selectedEmail.length > 0 &&
        selectedEmail.trim()?.length > 0 &&
        subject?.trim()?.length > 0 &&
        editorValue?.length > 0 &&
        !(!user?.refresh_token && user?.email == selectedEmail)
      )
    ) {
      setLoading(false);
      console.log("Please enter all required data");
      setMessage({
        type: "error",
        message: "Please enter all required data",
      });
      return;
    }

    const formData = new FormData();
    const token = await core.getData(core.data.ACCESS_TOKEN);

    // Append each file to the FormData object
    for (let i = 0; i < attachments.length; i++) {
      formData.append("files", attachments[i]);
    }

    try {
      if (attachments.length > 0) {
        await fetch(core.S3_API.POST_UPLOAD_FILE, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
            uid: user?.id,
          },
          body: formData,
        })
          .then((res) => res.json())
          .then((data) => {
            console.log(data?.data, data);
            sendEmail(data?.data, executeOn);
          });
      } else {
        sendEmail([], executeOn);
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      setLoading(false);
    }
  };

  const getTemplate = async () => {
    setSubject("");
    setEditorStateDesc(
      EditorState?.createWithContent(
        ContentState?.createFromBlockArray(
          convertFromHTML(new String("Generating message...").toString()),
        ),
      ),
    );
    try {
      const json = await core.API(
        core.API_METHODS.POST,
        core.RECRUITER_API.POST_EMAIL_TEMPLATE,
        1,
        {
          company_name: companydata,
          job_position: position,
          candidate_name: chatDetail?.first_name,
          candidate_matching_comment: chatDetail?.summary,
          matchId: chatDetail?._id,
        },
      );

      if (json?.data) {
        setSubject(json?.data?.data?.subject);

        if (json?.data?.data?.attachments) {
          setAttachmentsUrl(json?.data?.data?.attachments);
        }

        if (typeof json?.data?.data?.body == "string") {
          setEditorStateDesc(
            EditorState?.createWithContent(
              ContentState?.createFromBlockArray(
                convertFromHTML(
                  new String(
                    json?.data?.data?.body?.split("&lt;").join("<"),
                  ).toString(),
                ),
              ),
            ),
          );
        } else {
          setEditorStateDesc(
            EditorState?.createWithContent(
              ContentState?.createFromBlockArray(
                convertFromHTML(new String("Failed to generate").toString()),
              ),
            ),
          );
        }
      }

      if (json?.error) {
        setEditorStateDesc(
          EditorState?.createWithContent(
            ContentState?.createFromBlockArray(
              convertFromHTML(new String("Failed to generate").toString()),
            ),
          ),
        );
      }
    } catch (error) {
      setEditorStateDesc(
        EditorState?.createWithContent(
          ContentState?.createFromBlockArray(
            convertFromHTML(new String("Failed to generate").toString()),
          ),
        ),
      );
    }
  };

  useEffect(() => {
    getTemplate();
    setEmailSuccess(false);
  }, [chatDetail]);

  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const getAllTemplates = async () => {
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        core.TEMPLATE_API.GET_ALL_TEMPLATE + user?.id,
        1,
      );
      if (json?.data) {
        setTemplates(json?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllTemplates();
  }, []);

  const replaceVariables = (text) => {
    const regex = /{{(.*?)}}/g;
    return text.replace(regex, (match, variable) => {
      switch (variable) {
        case "first_name":
          return user?.name || "{{first_name}}";
        case "last_name":
          return user?.last_name || "";
        case "org_name":
          return user?.organization?.name || "";
        case "job_name":
          return position || "";
        default:
          return match;
      }
    });
  };

  useEffect(() => {
    if (selectedTemplate) {
      setSubject(
        replaceVariables(
          templates?.find((template) => template._id === selectedTemplate)
            ?.subject,
        ),
      );

      if (
        templates?.find((template) => template._id === selectedTemplate)
          ?.attachments
      ) {
        setAttachmentsUrl(
          templates?.find((template) => template._id === selectedTemplate)
            ?.attachments,
        );
      }

      setEditorStateDesc(
        EditorState?.createWithContent(
          ContentState?.createFromBlockArray(
            convertFromHTML(
              new String(
                replaceVariables(
                  templates
                    ?.find((template) => template._id === selectedTemplate)
                    ?.body?.split("&lt;")
                    .join("<"),
                ),
              ).toString(),
            ),
          ),
        ),
      );
    }
  }, [selectedTemplate]);

  const handleAttachment = (event) => {
    const files = Array.from(event.target.files);
    setAttachments([...attachments, ...files]);
  };

  const removeAttachment = (index) => {
    setAttachments(attachments.filter((_, i) => i !== index));
  };

  const removeAttachmentUrl = (index) => {
    const newAttachmentsUrl = [...attachmentsUrl];
    newAttachmentsUrl.splice(index, 1);
    setAttachmentsUrl(newAttachmentsUrl);
  };

  return (
    <Modal
      modalDismiss={modalDismiss}
      message={message}
      heading={"Send email to " + to}
      subheading={
        "Verify the email's content and then proceed to send it to the candidate."
      }
      noBtns={true}
      className={"md:max-w-[60%] "}
    >
      {!emailSuccess ? (
        <>
          <div className="w-full ">
            <div className=" mt-[20px] w-full">
              <label
                className=" text-right text-[14px] font-normal not-italic"
                htmlFor=""
              >
                Template (optional)
              </label>
              <SelectAndSearch
                placeholder="Select template"
                data={templates?.map((template) => ({
                  name: template.template_name,
                  id: template._id,
                }))}
                setSelectedTemplate={setSelectedTemplate}
              />
            </div>
            <div className=" mt-[20px] w-full">
              <label
                className=" text-right text-[14px] font-normal not-italic required"
                htmlFor=""
              >
                From
                {!user?.refresh_token && user?.email == selectedEmail && (
                  <span className="text-red-400 text-xs font-normal ml-2">
                    {`( Your email is not verified. Please verify your email before
                    sending the email.)`}
                  </span>
                )}
              </label>
              <SelectAndSearch
                placeholder="Select template"
                defalultValue={user?.email}
                data={[
                  { name: user.email, id: user.email },
                  {
                    name: "no-reply@sproutsai.com",
                    id: "no-reply@sproutsai.com",
                  },
                ]}
                setSelectedTemplate={setSelectedEmail}
              />
            </div>
            <div className=" mt-[20px]">
              <SearchUser
                label={"Cc"}
                emails={selectedOptions}
                removerEmail={(e) =>
                  setSelectedOptions(selectedOptions.filter((_, i) => i !== e))
                }
                setEmails={(e) => {
                  setSelectedOptions([...selectedOptions, e]);
                }}
              />
            </div>

            <div className="  mt-[20px]  w-full">
              <label
                className="  text-[14px] font-normal not-italic required"
                htmlFor=""
              >
                Subject
              </label>
              <InputBox
                type="text"
                value={subject}
                size={"medium"}
                onChange={(e) => setSubject(e?.target?.value)}
              />
            </div>
            <div className="mt-[20px]  relative w-full" id="description">
              <label
                className=" text-right text-[14px] mt-[15px] font-normal not-italic"
                htmlFor=""
              >
                Body
              </label>
              <Editor
                editorState={editorStateDesc}
                onEditorStateChange={setEditorStateDesc}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class prose !min-w-full"
                toolbarClassName="toolbar-class"
              />
            </div>
            <div className="mt-[20px]">
              <Attachments
                attachments={attachments}
                attachmentsUrl={attachmentsUrl}
                removeAttachment={removeAttachment}
                removeAttachmentUrl={removeAttachmentUrl}
                handleAttachment={handleAttachment}
              />
            </div>
          </div>
          <div>
            {/* email schedule component */}
            <EmailScheduler
              handleSubmit={(executeOn) => handleSubmit(executeOn)}
              disabled={
                sendText === "Sending..." ||
                editorValue === "Generating message..." ||
                subject?.length === 0
              }
              sendText={sendText}
            />
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="60"
              height="60"
              viewBox="0 0 60 60"
              fill="none"
            >
              <rect width="60" height="60" rx="20" fill="#F3F9E6" />
              <rect
                x="7"
                y="7"
                width="45.3659"
                height="45.3659"
                rx="12"
                fill="#80BB01"
              />
              <path
                d="M29.1061 31.9281L30.0096 32.8359C30.4005 33.2288 31.0363 33.2288 31.4272 32.8359L39.5742 24.6493C40.0216 24.1998 40.7492 24.1998 41.1966 24.6493C41.6409 25.0958 41.6409 25.8174 41.1966 26.2639L31.7652 35.7412C31.1879 36.3213 30.2489 36.3213 29.6716 35.7412L24.2549 30.2982C23.8106 29.8517 23.8106 29.1301 24.2549 28.6836C24.7023 28.2341 25.43 28.2341 25.8774 28.6836L27.4926 30.3068L29.1061 31.9281ZM29.9094 29.4881C29.4663 29.0429 29.4663 28.3234 29.9093 27.8782L33.9539 23.813C34.4 23.3647 35.1257 23.3646 35.5719 23.8129C36.0149 24.2581 36.0149 24.9776 35.5719 25.4229L31.5273 29.488C31.0812 29.9364 30.3555 29.9364 29.9094 29.4881ZM25.0775 34.3651C25.5215 34.8113 25.5215 35.5323 25.0775 35.9785C24.6304 36.4278 23.9033 36.4278 23.4562 35.9785L17.8034 30.2982C17.3591 29.8517 17.3594 29.1298 17.8038 28.6833C18.2507 28.2341 18.9781 28.2338 19.4251 28.6829L20.2275 29.4892C20.2278 29.4895 20.2278 29.49 20.2275 29.4904C20.2272 29.4907 20.2272 29.4912 20.2275 29.4915L25.0775 34.3651Z"
                fill="white"
                stroke="white"
                strokeWidth="0.2"
              />
            </svg>
            <span className="text-[#16182F] text-24 font-semibold not-italic leading-[32px]">
              Email successfully sent to {chatDetail?.first_name}
            </span>
          </div>
          <div className="mt-[28px] flex justify-center">
            <button
              className="bg-bg1 rounded-[8px] py-[12px] px-[16px]"
              onClick={(e) => {
                setEmailSuccess(false);
                modalDismiss();
              }}
              data-dismiss="modal"
            >
              <span className="text-[#252E4B] text-[16px] font-normal not-italic flex items-center">
                <svg
                  className="mr-[5px]"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <path
                    d="M7.97508 5.65625L2.91675 10.7146L7.97508 15.7729"
                    stroke="#252E4B"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M17.0834 10.7188H3.05835"
                    stroke="#252E4B"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>{" "}
                Back to job{" "}
              </span>
            </button>
          </div>
        </>
      )}
    </Modal>
  );
}

export default SendEmail;
