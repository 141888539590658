import React from "react";
import TemplateData from "../../recruiterTemplates/TemplateData";
import moment from "moment";

const TemplateList = ({ data }) => {
  return (
    <div className="w-full h-full flex flex-col gap-4">
      {data?.map((iter, index) =>
        iter?.template_id ? (
          <div
            key={index}
            className={`w-full border-gray-500 ${index == data?.length - 1 ? "" : "border-b"} flex flex-col gap-4 pb-4`}
          >
            <TemplateData data={iter?.template} showType={false} />
          </div>
        ) : (
          <div className="border-gray-500 border-b ">
            {iter?.wait_type == "Particular Date" ? (
              <div className="w-full flex flex-col gap-1">
                <h2 className="text-gray-700 text-base not-italic font-normal ">
                  Trigger Next Email on
                </h2>
                <div className="w-full flex gap-2 items-center">
                  <p className="py-1 px-1.5 border-gray-300 border rounded-lg text-sm font-normal not-italic text-gray-800">
                    {moment(iter?.execute_on).format("DD MMM YYYY hh:mm A")}
                  </p>
                </div>
              </div>
            ) : (
              <div className="w-full flex flex-col gap-1">
                <h2 className="text-gray-700 text-base not-italic font-normal ">
                  Follow up in
                </h2>
                <div className="w-full flex gap-2 items-center">
                  <p className="py-1 px-1.5 border-gray-300 border rounded-lg text-sm font-normal not-italic text-gray-800">
                    {+iter?.wait_time}
                  </p>
                  <label className="text-right text-sm font-normal not-italic !mb-0">
                    days
                  </label>
                </div>
              </div>
            )}
          </div>
        ),
      )}
    </div>
  );
};

export default TemplateList;
