import React, { useRef } from "react";
import { ImAttachment } from "react-icons/im";

function Attachments({
  attachments,
  attachmentsUrl,
  removeAttachment,
  removeAttachmentUrl,
  handleAttachment,
}) {
  const fileInputRef = useRef(null);

  return (
    <div className="mb-2 flex flex-col gap-2">
      <input
        type="file"
        multiple
        onChange={handleAttachment}
        className="hidden"
        ref={fileInputRef}
      />
      <div className="flex flex-wrap gap-2">
        {attachmentsUrl?.map((file, index) => (
          <span
            key={index}
            className="px-4 py-2 border-gray-600 bg-gray-200 flex items-center gap-1 rounded-lg  text-blue-600 text-sm font-medium not-italic"
          >
            <ImAttachment />
            {file?.name}
            <button
              onClick={() => removeAttachmentUrl(index)}
              className=" text-red-500"
            >
              ×
            </button>
          </span>
        ))}
        {attachments.map((file, index) => (
          <span
            key={index}
            className="px-4 py-2 border-gray-600 bg-gray-200 flex items-center gap-1 rounded-lg  text-blue-600 text-sm font-medium not-italic"
          >
            <ImAttachment />
            {file.name}
            <button
              onClick={() => removeAttachment(index)}
              className=" text-red-500"
            >
              ×
            </button>
          </span>
        ))}
        <button
          onClick={() => fileInputRef.current.click()}
          className="px-4 py-1 border-gray-600 border-dashed border-button border-2 rounded-lg  text-gray-600 text-sm font-medium not-italic"
        >
          + Attach files
        </button>
      </div>
    </div>
  );
}

export default Attachments;
