import React, { useState } from "react";
import TemplateForm from "./TemplateForm";
import { useParams } from "react-router-dom";
import { getCharacterCountWithoutHTML } from "./getCharCount";
import { useEffect } from "react";
import { useContext } from "react";
const core = require("../../../lib/core");

const EditTemplate = ({ data, setEdit, reload }) => {
  const template_id = useParams().id;
  const [message, setMessage] = useState({});
  const [bodyLen, setBodyLen] = useState(0);
  const [attachments, setAttachments] = useState([]);
  const [attachmentsUrl, setAttachmentsUrl] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    name: data?.template_name,
    type: data?.template_type,
    subject: data?.subject,
    body: data?.body,
  });

  const { user } = useContext(core.UserContext);

  useEffect(() => {
    if (data?.attachments) {
      setAttachmentsUrl(data?.attachments);
    }
  }, [data]);

  const removeAttachmentUrl = (index) => {
    const newAttachmentsUrl = [...attachmentsUrl];
    newAttachmentsUrl.splice(index, 1);
    setAttachmentsUrl(newAttachmentsUrl);
  };

  const setData = (key, value) => {
    setForm({ ...form, [key]: value });
  };

  const editTemplate = async (attachmentlinks) => {
    let json = await core.API(
      core.API_METHODS.PUT,
      core.TEMPLATE_API.PUT_TEMPLATE_DATA + template_id,
      1,
      {
        template_name: form?.name,
        template_type: form?.type,
        subject: form?.subject,
        body: form?.body,
        attachments: [...(attachmentlinks || []), ...(attachmentsUrl || [])],
      },
    );
    if (json?.data) {
      setLoading(false);
      reload();
      setMessage({ type: "success", message: "Template updated successfully" });
      setTimeout(() => {
        setMessage({});
        setEdit(false);
      }, 2500);
    } else {
      setLoading(false);
      setMessage({
        type: "error",
        message:
          json?.message ?? "Unable to update the template. Please try again",
      });
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (
      !(
        form?.name?.trim()?.length > 0 &&
        form?.type?.trim()?.length > 0 &&
        form?.subject?.trim()?.length > 0 &&
        bodyLen > 0
      )
    ) {
      setLoading(false);
      setMessage({
        type: "error",
        message: "Please enter all required data",
      });
      return;
    }

    const formData = new FormData();
    const token = await core.getData(core.data.ACCESS_TOKEN);

    // Append each file to the FormData object
    for (let i = 0; i < attachments.length; i++) {
      formData.append("files", attachments[i]);
    }

    try {
      if (attachments.length > 0) {
        await fetch(core.S3_API.POST_UPLOAD_FILE, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
            uid: user?.id,
          },
          body: formData,
        })
          .then((res) => res.json())
          .then((data) => {
            console.log(data?.data, data);
            editTemplate(data?.data);
          });
      } else {
        editTemplate();
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      setLoading(false);
    }
  };

  return (
    <>
      <TemplateForm
        formData={form}
        setData={setData}
        bodyCharCount={setBodyLen}
        setAttachments={setAttachments}
        attachments={attachments}
        attachmentsUrl={attachmentsUrl}
        removeAttachmentUrl={removeAttachmentUrl}
      />
      <div className="w-full flex items-center gap-4 flex-row-reverse self-end">
        <button
          onClick={handleSubmit}
          disabled={loading}
          className="btn-md btn-primary"
        >
          Save
        </button>
        <button onClick={() => setEdit(false)} className="btn-md btn-sec">
          Cancel
        </button>
        {Object?.entries(message)?.length > 0 && (
          <p
            style={
              message?.type == "error" ? { color: "red" } : { color: "green" }
            }
            className="block text-sm font-medium grow"
          >
            {message?.message}
          </p>
        )}
      </div>
    </>
  );
};

export default EditTemplate;
