import React, { useMemo, useState } from "react";
import Question from "./screening/Question";
import { ScreeningQuestionContext } from "../../../lib/context";
import { ANSWERTYPES } from "../../../lib/constants";

function ScreeningQuestion({ quArray, setQuArray }) {
  const handleButtonClick = (index) => {
    const updatedArray = quArray.map((item, idx) => {
      if (idx === index) return { ...item, status: !item.status };
      return item;
    });
    setQuArray(updatedArray);
  };

  const addCustomQuestion = () => {
    const updatedArray = [
      ...quArray,
      {
        id: quArray.length,
        name: "Custom question " + (quArray.length + 1),
        status: true,
        question: "",
        required: false,
        answerType: ANSWERTYPES?.TEXT,
      },
    ];
    setQuArray(updatedArray);
  };

  const updateQuestion = (quesId, key, value) => {
    const updatedArray = quArray.map((item) => {
      if (quesId === item?.id) return { ...item, [key]: value };
      return item;
    });
    setQuArray(updatedArray);
  };

  const selectedQuestions = useMemo(
    () => quArray.filter((item) => item?.status == true),
    [quArray],
  );

  return (
    <ScreeningQuestionContext.Provider
      value={{ data: quArray, setData: updateQuestion }}
    >
      <div className="w-full input-data-section" id="screening-question">
        {/* Question selection tags */}
        <div className="w-full py-5 flex flex-wrap gap-2">
          {quArray.map((item, index) => (
            <button
              key={index}
              type="button"
              className={`${item.status ? "hover:!bg-[#699c8f]" : "hover:!bg-[#00b98e34]"} rounded-[68px] flex text-sm border border-gray-500 items-center gap-1.5 p-2 px-2.5`}
              style={{
                background: item?.status
                  ? "#00B98D"
                  : "rgba(51, 52, 142, 0.03)",
                transition: "background-color 0.5s",
              }}
              onClick={() => handleButtonClick(index)}
            >
              <span
                style={{
                  color: item?.status ? "#FFFFFF" : "#252E4B",
                  transition: "color 0.5s",
                }}
              >
                {item?.name}
              </span>
              {!item?.status ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <g id="add">
                    <path
                      id="Vector"
                      d="M4 8H12"
                      stroke="#252E4B"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      id="Vector_2"
                      d="M8 12V4"
                      stroke="#252E4B"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <g id="Right icon">
                    <path
                      id="Vector"
                      d="M4.66797 4.66602L11.3346 11.3327"
                      stroke="white"
                      strokeWidth={1.5}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      id="Vector_2"
                      d="M4.66732 11.3327L11.334 4.66602"
                      stroke="white"
                      strokeWidth={1.5}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>
              )}
            </button>
          ))}
          <button
            type="button"
            className={`hover:!bg-[#00b98e34] rounded-[68px] flex text-sm border border-gray-500 items-center gap-1.5 p-2 px-2.5`}
            style={{
              background: "rgba(51, 52, 142, 0.03)",
              transition: "background-color 0.5s",
            }}
            onClick={addCustomQuestion}
          >
            <span
              style={{
                color: "#252E4B",
                transition: "color 0.5s",
              }}
            >
              Add custom question
            </span>
          </button>
        </div>
        {/* Selected questions */}
        {selectedQuestions?.map((qu, idx) => (
          <Question data={qu} index={qu?.id} />
        ))}
      </div>
    </ScreeningQuestionContext.Provider>
  );
}

export default ScreeningQuestion;
