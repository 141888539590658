// import React from 'react'
// import calenderImg from "../../assets/OnlineProcesses.png"

// function DashboardInterviewSection() {

//     return (
//         <div className='bg-[#FFF] rounded-[12px] pt-[18px] px-[18px] pb-[20px] shadow-PipelineCardsShadow'>
//             <span className='text-[16px] text-[#252E4B] font-medium not-italic'>Today’s Interviews</span>
//             {/* <div>

//                 <div className='mt-[18px]'>
//                     <span className='text-[12px] text-[#5F6989] font-normal not-italic'>9:45-10:30 PM</span>
//                     <span className='text-[12px] text-[#FF8A00] font-medium not-italic ml-[5px]'>(starts in 02 min)</span>
//                 </div>
//                 <span className='mt-[10px] text-[16px] text-[#252E4B] font-medium not-italic '> Round 1 - Interview with sourav</span>
//                 <span className='mt-[10px] text-[16px] flex items-center text-[#252E4B] font-medium not-italic '>
//                     <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
//                         <g clipPath="url(#clip0_3879_25594)">
//                             <path d="M14.2945 3.57354C14.8171 3.15912 15.3401 2.7449 15.8625 2.33008C16.2092 2.05486 16.5549 1.77864 16.9015 1.50362C17.0747 1.36621 17.2652 1.3344 17.4663 1.43005C17.6667 1.5255 17.7635 1.69333 17.7722 1.91427C17.7728 1.92759 17.7724 1.94071 17.7724 1.95404C17.7724 5.24454 17.7724 8.53504 17.7722 11.8255C17.7722 12.1246 17.6187 12.3302 17.3472 12.3933C17.1746 12.4335 17.0218 12.3873 16.8855 12.277C16.0461 11.5981 15.2063 10.9195 14.3665 10.241C14.3487 10.2267 14.3301 10.2136 14.3067 10.1963C14.2788 10.2343 14.2905 10.2747 14.2903 10.311C14.2895 11.0699 14.2907 11.8287 14.2893 12.5876C14.2883 13.1835 13.9442 13.6906 13.3957 13.9078C13.2471 13.9666 13.0923 13.9977 12.9325 13.9977C10.344 13.9979 7.75553 13.9979 5.16706 13.9977C5.14727 13.9977 5.12768 13.9957 5.10789 13.9945C5.10809 12.6154 5.10829 11.2361 5.10809 9.85705C5.10809 9.82424 5.10374 9.79123 5.10156 9.75842L5.10671 9.75683L5.11205 9.75782C6.83103 9.75782 8.54982 9.75782 10.2781 9.75782C10.2781 9.70274 10.2781 9.66376 10.2781 9.62459C10.2781 8.83651 10.2779 8.04844 10.2783 7.26016C10.2783 7.17088 10.2815 7.08159 10.283 6.9923L10.292 6.9933C10.3155 7.01617 10.3373 7.04122 10.3628 7.06151C10.7498 7.36954 11.1379 7.67637 11.5249 7.98441C11.9611 8.33141 12.3962 8.67961 12.8321 9.02682C13.1984 9.31854 13.5649 9.61007 13.9322 9.9006C14.0418 9.9873 14.1546 10.0702 14.2676 10.1561C14.3067 10.1009 14.2933 10.0531 14.2933 10.0086C14.2941 7.92475 14.2939 5.84111 14.2937 3.75728C14.2937 3.70757 14.2905 3.65805 14.2887 3.60834C14.2909 3.5968 14.2927 3.58507 14.2945 3.57354Z" fill="#00AB47" />
//                             <path d="M14.2934 3.57527C14.2916 3.587 14.2899 3.59853 14.2881 3.61027C14.2697 3.62419 14.2507 3.63731 14.2331 3.65223C13.2631 4.47351 12.2929 5.2944 11.3233 6.11628C10.9783 6.4086 10.635 6.70231 10.2909 6.99543L10.282 6.99443C10.2804 6.95486 10.2773 6.91529 10.2773 6.87571C10.2771 6.03474 10.2773 5.19397 10.2765 4.353C10.2765 4.3182 10.2879 4.28082 10.2604 4.24204C10.2242 4.24204 10.1852 4.24204 10.1461 4.24204C8.50544 4.24204 6.86482 4.24204 5.2242 4.24224C5.18462 4.24224 5.14524 4.24403 5.10567 4.24502L5.10943 4.23906C5.10903 3.24198 5.10863 2.24511 5.10804 1.24803C5.10765 0.83401 5.10646 0.419988 5.10547 0.00596574C5.14148 0.00417602 5.17769 0.000596574 5.21371 0.000596574C7.76953 0 10.3253 0 12.8812 0C13.676 0 14.2877 0.616659 14.2883 1.41806C14.2887 2.09736 14.2883 2.77685 14.2887 3.45615C14.2885 3.49592 14.2916 3.53569 14.2934 3.57527Z" fill="#FEB900" />
//                             <path d="M0.891432 9.75666C0.889255 9.72047 0.885099 9.68428 0.885099 9.64808C0.884704 7.88242 0.884704 6.11676 0.885099 4.35091C0.885099 4.31213 0.874612 4.27116 0.898555 4.23457C0.941297 4.23537 0.984237 4.23716 1.02698 4.23716C2.20357 4.23736 3.38037 4.23736 4.55696 4.23716C4.74138 4.23716 4.92581 4.23537 5.11023 4.23438L5.10647 4.24034C5.10667 6.03583 5.10687 7.83112 5.10746 9.62661C5.10746 9.66956 5.11023 9.71251 5.11162 9.75547L5.10627 9.75447L5.10113 9.75606C3.69816 9.75626 2.2948 9.75646 0.891432 9.75666Z" fill="#2683FB" />
//                             <path d="M0.8898 9.76642C2.29316 9.76622 3.69653 9.76582 5.09989 9.76562C5.10227 9.79844 5.10642 9.83145 5.10642 9.86426C5.10662 11.2433 5.10642 12.6226 5.10623 14.0017C4.57907 14.0029 4.05192 14.0043 3.52477 14.0049C3.11615 14.0055 2.70752 14.0057 2.2991 14.0049C1.50897 14.0031 0.883665 13.3725 0.883269 12.5769C0.882478 11.6765 0.882874 10.7756 0.883269 9.875C0.883269 9.8388 0.887425 9.80261 0.8898 9.76642Z" fill="#0065D8" />
//                             <path d="M5.11011 4.23309C4.92569 4.23408 4.74127 4.23587 4.55684 4.23587C3.38025 4.23607 2.20346 4.23607 1.02686 4.23587C0.98412 4.23587 0.94118 4.23428 0.898438 4.23329C0.914862 4.2132 0.929505 4.19133 0.94771 4.17303C2.32119 2.79236 3.69507 1.41189 5.06895 0.0314196C5.08043 0.0198858 5.09369 0.0103406 5.10616 0C5.10695 0.414022 5.10833 0.828045 5.10873 1.24207C5.10932 2.23914 5.10972 3.23602 5.11011 4.23309Z" fill="#E94335" />
//                             <path d="M10.293 6.99453C10.6371 6.70142 10.9804 6.40751 11.3253 6.11538C12.2949 5.2937 13.2649 4.47262 14.2351 3.65133C14.2527 3.63642 14.2717 3.6233 14.2901 3.60938C14.2919 3.65909 14.2951 3.70861 14.2951 3.75832C14.2953 5.84215 14.2955 7.92579 14.2947 10.0096C14.2947 10.0542 14.3082 10.1019 14.269 10.1572C14.156 10.0713 14.0432 9.98834 13.9336 9.90164C13.5663 9.61111 13.1998 9.31958 12.8335 9.02786C12.3976 8.68065 11.9623 8.33245 11.5264 7.98544C11.1393 7.67741 10.7513 7.37058 10.3642 7.06254C10.3383 7.04246 10.3165 7.0174 10.293 6.99453Z" fill="#00832D" />
//                         </g>
//                         <defs>
//                             <clipPath id="clip0_3879_25594">
//                                 <rect width="16.8892" height="14" fill="white" transform="translate(0.882812)" />
//                             </clipPath>
//                         </defs>
//                     </svg>

//                     Google meet
//                 </span>
//             </div> */}

//             {/* <div className='flex justify-center items-center flex-col'>
//                 <img src={calenderImg} alt="" />
//                 <span className='mt-[16px]  text-[14px] flex items-center text-[#252E4B] font-medium not-italic'>Enjoy your no meeting day!</span>
//             </div> */}
//         </div>
//     )
// }

// export default DashboardInterviewSection

import React, { useState, useContext, useEffect } from "react";
import { BsCameraVideo } from "react-icons/bs";
import { FiExternalLink, FiClock } from "react-icons/fi";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import InitialsLogo from "../../all/InitialsLogo";
import calenderImg from "../../assets/OnlineProcesses.png";
const core = require("../../../lib/core");

function DashboardInterviewSection() {
  const [showUpcoming, setShowUpcoming] = useState(false);
  const [timeLeft, setTimeLeft] = useState("");
  const [interviews, setInterviews] = useState([]);
  const [todayMeetings, setTodayMeetings] = useState([]);
  const { user } = useContext(core.UserContext);

  useEffect(() => {
    async function getInterviews() {
      try {
        let response = await core.API(
          core.API_METHODS.GET,
          core.RECRUITER_API.GET_INTERVIEWS_SCHEDULE,
          1,
          {},
        );
        const interviewsData = response.data || [];
        const today = new Date();

        // Filter today's meetings
        const filteredMeetings = interviewsData.filter((interview) => {
          const interviewStart = new Date(interview.start_time);
          return (
            interviewStart.getDate() === today.getDate() &&
            interviewStart.getMonth() === today.getMonth() &&
            interviewStart.getFullYear() === today.getFullYear()
          );
        });

        // Filter out today's meetings from all interviews
        const otherInterviews = interviewsData.filter((interview) => {
          const interviewStart = new Date(interview.start_time);
          return !(
            interviewStart.getDate() === today.getDate() &&
            interviewStart.getMonth() === today.getMonth() &&
            interviewStart.getFullYear() === today.getFullYear()
          );
        });

        setTodayMeetings(filteredMeetings); // Set today's meetings
        setInterviews(otherInterviews); // Set interviews excluding today's meetings
      } catch (err) {
        console.log("Error fetching the candidates:", err);
      }
    }

    getInterviews();
  }, []);

  useEffect(() => {
    // Set up an interval to update the component every minute
    const timer = setInterval(() => {
      // This will trigger a re-render every minute
      setTimeLeft(new Date());
    }, 60000);

    // Clear interval on component unmount
    return () => clearInterval(timer);
  }, []);

  const handleJoinClick = (link) => {
    window.open(link, "_blank");
  };

  const calculateTimeLeft = (startTime, endTime) => {
    const now = new Date();
    const interviewStart = new Date(startTime);
    const interviewEnd = new Date(endTime);
    const difference = interviewStart - now;

    if (now > interviewEnd) {
      return "Interview Ended";
    } else if (now >= interviewStart && now <= interviewEnd) {
      return "Interview Started";
    } else if (difference > 0) {
      const hoursLeft = Math.floor(difference / (1000 * 60 * 60));
      const minutesLeft = Math.floor(
        (difference % (1000 * 60 * 60)) / (1000 * 60),
      );

      // Return time left only if it's less than 24 hours
      if (hoursLeft < 24) {
        return `(Starts in ${hoursLeft > 0 ? `${hoursLeft} hrs ` : ""}${minutesLeft} min)`;
      }
    }
    return null; // Return null if time is 24 hours or more
  };

  // Helper function to group interviews by date
  const groupInterviewsByDate = (interviews) => {
    const grouped = {};
    interviews.forEach((interview) => {
      const date = new Date(interview.start_time).toLocaleDateString("en-US", {
        weekday: "long",
        day: "numeric", // Keep day first to get the correct order
      });

      // Rearrange to "Saturday 31"
      const [day, weekday] = date.split(" ");
      const formattedDate = `${weekday} ${day}`;

      if (!grouped[formattedDate]) {
        grouped[formattedDate] = [];
      }
      grouped[formattedDate].push(interview);
    });
    return grouped;
  };

  const groupedInterviews = groupInterviewsByDate(interviews);

  return (
    <div className="bg-white rounded-[12px] shadow-PipelineCardsShadow">
      <div className="flex justify-between items-center px-5 pt-5 ">
        <span className="text-md text-[#252E4B] font-medium">
          Today’s Interviews
        </span>
      </div>

      <div className="mt-2 mb-4 px-5">
        {todayMeetings.length === 0 ? (
          <div className=" flex justify-center items-center flex-col">
            <img src={calenderImg} alt="No Meetings" />
            <span className="mt-[16px] text-[14px] flex items-center text-[#252E4B] font-medium not-italic">
              Enjoy your no meeting day!
            </span>
          </div>
        ) : (
          todayMeetings.map((meeting) => (
            <div key={meeting._id} className="my-7">
              <div className="flex items-center space-x-2 text-xs">
                <FiClock className="text-gray-700" />
                <span className="text-gray-700">
                  {new Date(meeting.start_time).toLocaleTimeString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })}{" "}
                  -{" "}
                  {new Date(meeting.end_time).toLocaleTimeString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })}
                </span>
                <div className="text-[#e88e2e]">
                  {calculateTimeLeft(meeting.start_time, meeting.end_time)}
                </div>
              </div>
              <div className="mt-2 text-[#252E4B] font-semibold flex hover:cursor-pointer">
                <div className="flex items-center group text-sm font-bold">
                  {meeting.title}
                </div>
              </div>
              <div className="mt-2.5 flex items-center space-x-3">
                <button
                  className="flex items-center px-2.5 py-1 text-gray-800 bg-white rounded-[8px] border border-gray-600 hover:scale-105 transition-all duration-300"
                  onClick={() => handleJoinClick(meeting.meeting_link)}
                >
                  <BsCameraVideo className="mr-3" />
                  Join now
                </button>
                <div className="flex justify-center items-center">
                  <div className="flex -space-x-3 ml-2">
                    <InitialsLogo
                      className="w-8 h-8"
                      str={user?.name}
                      width="28px"
                      height="28px"
                      borderRadius="50%"
                      fontSize="12px"
                    />
                    {meeting.guest.map((guest, index) => (
                      <InitialsLogo
                        key={index}
                        className="w-8 h-8"
                        str={guest}
                        width="28px"
                        height="28px"
                        borderRadius="50%"
                        fontSize="12px"
                      />
                    ))}
                  </div>
                  <span className="text-xs text-gray-700 ml-2">
                    You and {meeting.guest.length} other
                    {meeting.guest.length > 1 ? "s" : ""}
                  </span>
                </div>
              </div>
            </div>
          ))
        )}
      </div>

      {/* Render this section only if there are upcoming interviews */}
      {interviews.length > 0 && (
        <>
          <hr className="border-gray-500" />

          <div className="mt-2 px-5 pb-2">
            <button
              className="flex items-center justify-between w-full text-sm text-gray-700"
              onClick={() => setShowUpcoming(!showUpcoming)}
            >
              See Other Interviews
              {showUpcoming ? (
                <MdExpandLess className="ml-2" />
              ) : (
                <MdExpandMore className="ml-2" />
              )}
            </button>
          </div>

          <div
            className={`overflow-hidden transition-all duration-500 ease-in-out ${
              showUpcoming ? "max-h-[1000px]" : "max-h-0"
            }`}
          >
            <div className="px-5 ">
              {Object.entries(groupedInterviews).map(([date, meetings]) => (
                <div key={date} className="mt-4">
                  <span className="text-gray-800 font-medium text-base">
                    {date}
                  </span>
                  {meetings.map((meeting, index) => (
                    <div key={meeting._id} className="mt-3 mb-4">
                      {/* {index === 0 && (
                        <span className="text-gray-800 font-medium text-sm">
                          {date}
                        </span>
                      )} */}
                      <div className="flex items-center space-x-2 text-xs">
                        <FiClock className="text-gray-700" />
                        <span className="text-gray-700">
                          {new Date(meeting.start_time).toLocaleTimeString(
                            "en-US",
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                            },
                          )}{" "}
                          -{" "}
                          {new Date(meeting.end_time).toLocaleTimeString(
                            "en-US",
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                            },
                          )}
                        </span>
                        {/* <div className="text-[#e88e2e]">
                          {calculateTimeLeft(meeting.start_time, meeting.end_time)}
                        </div> */}
                      </div>
                      <div className="mt-2 text-[#252E4B] font-semibold flex hover:cursor-pointer">
                        <div className="flex items-center group text-sm font-bold">
                          {meeting.title}
                        </div>
                      </div>
                      <div className="mt-2.5 flex items-center space-x-3">
                        {/* <button
                          className="flex items-center px-2.5 py-1 text-gray-800 bg-white rounded-[8px] border border-gray-600 hover:scale-105 transition-all duration-300"
                          onClick={() => handleJoinClick(meeting.meeting_link)}
                        >
                          <BsCameraVideo className="mr-3" />
                          Join now
                        </button> */}
                        <div className="flex justify-center items-center mb-2">
                          <div className="flex -space-x-3 ">
                            <InitialsLogo
                              className="w-8 h-8"
                              str={user?.name}
                              width="28px"
                              height="28px"
                              borderRadius="50%"
                              fontSize="12px"
                            />
                            {meeting.guest.map((guest, index) => (
                              <InitialsLogo
                                key={index}
                                className="w-8 h-8"
                                str={guest}
                                width="28px"
                                height="28px"
                                borderRadius="50%"
                                fontSize="12px"
                              />
                            ))}
                          </div>
                          <span className="text-xs text-gray-700 ml-2">
                            You and {meeting.guest.length} other
                            {meeting.guest.length > 1 ? "s" : ""}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default DashboardInterviewSection;
