import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaRegCalendar, FaRegClock, FaChevronDown } from "react-icons/fa";
import { IoMdArrowDropdown } from "react-icons/io";
import moment from "moment-timezone";

const EmailScheduler = ({ handleSubmit, disabled, sendText }) => {
  const [scheduleOption, setScheduleOption] = useState("send_now");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [scheduledTime, setScheduledTime] = useState(null);
  const scheduleOptions = [
    { value: "send_now", label: "Send now" },
    { value: "tomorrow", label: "Tomorrow" },
    { value: "2_days", label: "2 days" },
    { value: "4_days", label: "4 days" },
    { value: "next_weekday", label: "Next weekday" },
    { value: "1_week", label: "1 week" },
    { value: "2_weeks", label: "2 weeks" },
    { value: "1_month", label: "1 month" },
    { value: "custom", label: "Custom time..." },
  ];

  const handleOptionChange = (e) => {
    setScheduleOption(e.target.value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectedTime(null); // to reset the time when the date changes
  };

  const handleTimeChange = (time) => {
    setSelectedTime(time);
  };

  const onSubmit = () => {
    let scheduledDateTime;
    const now = moment();
    const userTimezone = moment.tz.guess();

    if (scheduleOption === "custom" && selectedDate && selectedTime) {
      scheduledDateTime = moment(selectedDate)
        .hours(selectedTime.getHours())
        .minutes(selectedTime.getMinutes());
    } else {
      switch (scheduleOption) {
        case "send_now":
          scheduledDateTime = now;
          break;
        case "tomorrow":
          scheduledDateTime = now
            .clone()
            .add(1, "days")
            .startOf("day")
            .add(now.hours(), "hours")
            .add(now.minutes(), "minutes");
          break;
        case "2_days":
          scheduledDateTime = now
            .clone()
            .add(2, "days")
            .startOf("day")
            .add(now.hours(), "hours")
            .add(now.minutes(), "minutes");
          break;
        case "4_days":
          scheduledDateTime = now
            .clone()
            .add(4, "days")
            .startOf("day")
            .add(now.hours(), "hours")
            .add(now.minutes(), "minutes");
          break;
        case "next_weekday":
          scheduledDateTime = now
            .clone()
            .add(1, "weeks")
            .startOf("isoWeek")
            .add(now.hours(), "hours")
            .add(now.minutes(), "minutes");
          break;
        case "1_week":
          scheduledDateTime = now
            .clone()
            .add(1, "weeks")
            .startOf("day")
            .add(now.hours(), "hours")
            .add(now.minutes(), "minutes");
          break;
        case "2_weeks":
          scheduledDateTime = now
            .clone()
            .add(2, "weeks")
            .startOf("day")
            .add(now.hours(), "hours")
            .add(now.minutes(), "minutes");
          break;
        case "1_month":
          scheduledDateTime = now
            .clone()
            .add(1, "months")
            .startOf("day")
            .add(now.hours(), "hours")
            .add(now.minutes(), "minutes");
          break;
        default:
          scheduledDateTime = now;
          break;
      }
    }

    // Convert to the user's timezone and format
    const localDate = scheduledDateTime.tz(userTimezone);
    const formattedDate = localDate.format();

    setScheduledTime(formattedDate);

    handleSubmit(formattedDate);
  };

  const filterTime = (time) => {
    const currentDate = new Date();
    const selectedDateTime = new Date(selectedDate);

    // Set the time of the selected date to the time being checked
    selectedDateTime.setHours(time.getHours(), time.getMinutes(), 0, 0);

    // If it's today, don't allow past times
    if (
      selectedDate &&
      selectedDate.getDate() === currentDate.getDate() &&
      selectedDate.getMonth() === currentDate.getMonth() &&
      selectedDate.getFullYear() === currentDate.getFullYear()
    ) {
      return currentDate.getTime() < selectedDateTime.getTime();
    }

    // For future dates, allow all times
    return true;
  };

  return (
    <div className="mt-4 space-y-4">
      <div className="flex items-center">
        <div className="relative w-48">
          <label
            className="text-right text-[14px] font-normal not-italic required"
            htmlFor=""
          >
            Schedule email
          </label>
          <select
            value={scheduleOption}
            onChange={handleOptionChange}
            className="w-full p-2 pr-8 border border-gray-400  rounded-lg text-base shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder:text-[14px] placeholder:font-normal active:border-[#1369E9] focus:border-[#1369E9] focus:shadow-inputShadow placeholder:text-[#A9A9AC]"
          >
            {scheduleOptions?.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <div className="absolute top-[35px] right-0 flex items-center px-2 pointer-events-none">
            <IoMdArrowDropdown size={20} className="text-gray-500" />
          </div>
        </div>
      </div>
      {scheduleOption === "custom" && (
        <div className="flex space-x-2">
          <div className="relative">
            <DatePicker
              selected={selectedDate}
              onChange={handleDateChange}
              dateFormat="MM/dd/yyyy"
              placeholderText="mm/dd/yyyy"
              minDate={new Date()} // Prevent selecting past dates
              className="w-full p-2 pl-10 border border-gray-400  rounded-lg text-base shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder:text-[14px] placeholder:font-normal active:border-[#1369E9] focus:border-[#1369E9] focus:shadow-inputShadow placeholder:text-[#A9A9AC]"
            />
            <FaRegCalendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-700" />
          </div>
          <div className="relative">
            <DatePicker
              selected={selectedTime}
              onChange={handleTimeChange}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
              filterTime={filterTime}
              placeholderText="--:-- --"
              className="w-full p-2 pl-10 border border-gray-400  rounded-lg text-base shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder:text-[14px] placeholder:font-normal active:border-[#1369E9] focus:border-[#1369E9] focus:shadow-inputShadow placeholder:text-[#A9A9AC]"
            />
            <FaRegClock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-700" />
          </div>
        </div>
      )}
      <div className="flex justify-end">
        <button
          disabled={
            disabled ||
            (scheduleOption === "custom" && (!selectedDate || !selectedTime))
          }
          onClick={onSubmit}
          className="solid-button-blue px-[20px] h-[40px] rounded-[8px] bg-[#1369E9]  shadow-jobPostButtonShadow flex items-center justify-center text-[#FFF] text-[16px] not-italic font-normal"
        >
          {scheduleOption === "send_now" ? sendText : "Schedule Email"}
        </button>
      </div>
    </div>
  );
};

export default EmailScheduler;
