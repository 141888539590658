import React, { useContext, useEffect, useState } from "react";
import {
  GoogleMeet,
  ZoomIcon,
  GoogleCalendar,
  OutlookCalendar,
} from "../../../../components/recruiter/candidate/icons/Icons";
import Google from "../../../../components/assets/recruiter/google.png";
import Microsoft from "../../../../components/assets/recruiter/microsoft.png";
import AuthCard from "./AuthCard";
const core = require("../../../../lib/core");

const Meetingauthorization = () => {
  const [gmeetAuthStatus, setGmeetAuthStatus] = useState(false);
  const [GoogleCalenderStatus, setGoogleCalenderStatus] = useState(false);
  const [OutlookCalendarStatus, setOutlookCalendarStatus] = useState(false);
  const [Service, setService] = useState("");
  const [zoomStatus, setZoomStatus] = useState(false);
  const { user } = useContext(core.UserContext);
  const meetAuthStatus = async () => {
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        core.SCHEDULING_API.GET_MEET_AUTH_STATUS +
          `${user.email}&service=google-meet`,
        1,
        {},
      );
      console.log("google", json);
      setGmeetAuthStatus(json?.data?.status);
      if (json?.data?.status) setService("google");
    } catch (e) {
      console.log(e);
    }
  };
  const getZoomstatus = async () => {
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        core.SCHEDULING_API.GET_MEET_AUTH_STATUS + `${user.email}&service=zoom`,
        1,
        {},
      );
      setZoomStatus(json?.data?.status);
      if (json?.data?.status) setService("zoom");
    } catch (e) {
      console.log(e);
    }
  };
  const getGooglecalenderStatus = async () => {
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        core.SCHEDULING_API.GET_MEET_AUTH_STATUS +
          `${user.email}&service=google-calendar`,
        1,
        {},
      );
      if (json?.data) {
        setGoogleCalenderStatus(json.data.status);
        if (json.data.status) setService("google");
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const getOutlookCalenderStatus = async () => {
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        core.SCHEDULING_API.GET_OUTLOOK_AUTH_STATUS +
          `${user.email}&service=microsoft`,
        1,
        {},
      );
      if (json?.data) {
        setOutlookCalendarStatus(json.data.status);
        if (json.data.status) setService("outlook");
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    meetAuthStatus();
    getZoomstatus();
    getGooglecalenderStatus();
    getOutlookCalenderStatus();
  }, []);

  const MeetingPlatforms = [
    {
      Logo: <GoogleMeet width="50" height="50" />,
      Name: "Google Meet",
      authType: 1,
      Link: `${core.SCHEDULING_API.AUTH_GOOGLE_MEET}${user?.email}`,
      disabled: Service === "outlook" && !gmeetAuthStatus,
      authorized: gmeetAuthStatus,
    },
    {
      Logo: <ZoomIcon width="45" height="45" />,
      Name: "Zoom",
      authType: 1,
      Link: `${core.SCHEDULING_API.AUTH_ZOOM_MEET}${user?.email}`,
      disabled: false,
      authorized: zoomStatus,
    },
  ];

  const CalenderPlatforms = [
    {
      Logo: <GoogleCalendar width="50" height="50" />,
      Name: "Google Calender",
      authType: 1,
      Link: `${core.SCHEDULING_API.AUTH_GOOGLE_CALENDAR}${user?.email}`,
      disabled: Service === "outlook" && !GoogleCalenderStatus,
      authorized: GoogleCalenderStatus,
    },
    {
      Logo: <OutlookCalendar width="50" height="50" />,
      Name: "Outlook Calender",
      authType: 1,
      Link: `${core.SCHEDULING_API.AUTH_OUTLOOK_CALENDAR}${user?.email}`,
      disabled: Service === "google" && !OutlookCalendarStatus,
      authorized: OutlookCalendarStatus,
    },
  ];

  const EmailPlatforms = [
    {
      Logo: <img src={Google} alt="google" className="w-12 h-12" />,
      Name: "Gmail",
      authType: 2,
      email_auth_type: "gmail",
      Link: core.GOOGLE_API.GET_EMAIL_AUTHENTICATION,
      disabled:
        user?.refresh_token && user?.email_auth_type == "outlook"
          ? true
          : false,
      authorized: user?.refresh_token ? true : false,
    },
    {
      Logo: <img src={Microsoft} alt="google" className="w-12 h-12" />,
      Name: "Outlook",
      authType: 2,
      email_auth_type: "outlook",
      Link: core.OUTLOOK_API.POST_OUTLOOK_AUTH,
      disabled:
        user?.refresh_token && user?.email_auth_type != "outlook"
          ? true
          : false,
      authorized: user?.refresh_token ? true : false,
    },
  ];
  return (
    <>
      <div className="bg-white shadow-md rounded-lg py-4 px-4 my-2 mx-auto">
        <h3 className="py-2 text-lg font-medium text-gray-800">Meetings</h3>

        <div className="flex felx-row gap-8 py-2 flex-wrap">
          {MeetingPlatforms.map((item, index) => {
            return (
              <>
                <AuthCard
                  Logo={item.Logo}
                  Name={item.Name}
                  Link={item.Link}
                  disabled={item.disabled}
                  authorized={item.authorized}
                  authType={item.authType}
                />
              </>
            );
          })}
        </div>
      </div>
      <div className="bg-white shadow-md rounded-lg py-4 px-4 my-2 mx-auto">
        <h3 className="py-2 text-lg font-medium text-gray-800">Calenders</h3>

        <div className="flex felx-row gap-8 py-2 flex-wrap">
          {CalenderPlatforms.map((item, index) => {
            return (
              <>
                <AuthCard
                  Logo={item.Logo}
                  Name={item.Name}
                  Link={item.Link}
                  disabled={item.disabled}
                  authorized={item.authorized}
                  authType={item.authType}
                />
              </>
            );
          })}
        </div>
      </div>
      <div className="bg-white shadow-md rounded-lg py-4 px-4 my-2 mx-auto">
        <h3 className="py-2 text-lg font-medium text-gray-800">Mail</h3>

        <div className="flex felx-row gap-8 py-2 flex-wrap">
          {EmailPlatforms.map((item, index) => {
            return (
              <>
                <AuthCard
                  Logo={item.Logo}
                  Name={item.Name}
                  authType={item.authType}
                  Link={item.Link}
                  disabled={item.disabled}
                  authorized={item.authorized}
                  email_auth_type={item.email_auth_type}
                />
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Meetingauthorization;
