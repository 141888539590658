import React from "react";
import { useNavigate } from "react-router-dom";

const Header = ({ orgDetails, jobDetails, isTemplateUsed, onScrollToForm }) => {
  const navigate = useNavigate();
  console.log(jobDetails);

  const title = orgDetails?.name || "TAILORBIRD";
  const viewAllJobsText = "View All Jobs";
  const jobTitle = jobDetails?.name || "Business Development Manager";
  const jobLocation =
    jobDetails?.location?.[0]?.name ||
    "New Jersey, New York, San Francisco - Remote";
  const jobworkplace = jobDetails?.workplace;

  const url = String(orgDetails?.domain_url).startsWith("http")
    ? orgDetails?.domain_url
    : `http://${orgDetails?.domain_url}`;

  return isTemplateUsed ? (
    <div
      className="w-full p-4 text-white flex flex-col"
      style={{ backgroundColor: orgDetails?.theme_color ?? "#002332" }}
    >
      <div className="max-w-5xl w-full mx-auto md:px-6 lg:px-0">
        <div className="mb-4 mt-5 flex flex-col">
          <h1 className="text-white text-4xl font-bold mb-2 text-center md:text-left">
            {title}
          </h1>
          <div
            className="text-[#55b3b0] uppercase text-sm tracking-wide hover:cursor-pointer text-center md:text-left"
            onClick={() =>
              (window.location.href = `/job-post/${orgDetails?.search_name} `)
            }
          >
            {viewAllJobsText}
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between items-center mt-6">
          <div className="text-center md:text-left mb-4 md:mb-0">
            <h1 className="text-2xl md:text-3xl font-bold">{jobTitle}</h1>

            <p className="mt-2 text-sm ">
              {jobLocation} - {jobworkplace}
            </p>
          </div>
          <button
            className="bg-[#004d71] hover:shadow-lg hover:scale-105 transition-all duration-300 text-white font-medium py-2 md:py-3 px-4 rounded"
            onClick={onScrollToForm}
          >
            Apply Now
          </button>
        </div>
      </div>
    </div>
  ) : (
    <div className="bg-gray-100 py-5 w-full flex justify-between items-center border-b border-b-gray-400 px-4 sm:px-[5%] gap-8">
      <a href={url}>
        <img
          src={orgDetails?.logo}
          alt="Logo"
          className="max-w-[50%] min-w-[10rem] h-auto max-h-[2.25rem] object-contain"
        />
      </a>
      <div
        className={`max-w-[50%] text-center text-white hover:text-white hover:no-underline no-underline font-medium px-6 py-2 rounded-md hover:scale-105 transition duration-300`}
        style={{ backgroundColor: orgDetails?.theme_color ?? "#1369E9" }}
      >
        <a
          href={url}
          className="hover:no-underline no-underline text-white hover:text-white"
          target="_blank"
          rel="noopener noreferrer"
        >
          Company Website
        </a>
      </div>
    </div>
  );
};

export default Header;
