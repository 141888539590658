import ProgressBar from "../../analytics/ProgressBar";
import { getTypeColor } from "../createJob2/TypesButton";
import { useEffect, useState } from "react";
import * as d3 from "d3";
import InitialsLogo from "../../all/InitialsLogo";
import moment from "moment/moment";
import { useSelector } from "react-redux";

export default function ProfileSection() {
  const profile = useSelector(
    (state) => state?.jobCandidateMatch?.profile?.matchProfile,
  );
  const { coverLetter, screeningAnswers } = useSelector((state) => {
    return {
      coverLetter:
        state?.jobCandidateMatch?.profile?.screeningQsnAns?.cover_latter,
      screeningAnswers:
        state?.jobCandidateMatch?.profile?.screeningQsnAns
          ?.screening_questions_answers,
    };
  });

  const [width, setWidth] = useState(500);
  const [expShowAll, setExpShowAll] = useState(false);
  const [eduShowAll, setEduShowAll] = useState(false);
  const [eduList, setEduList] = useState([]);
  const [expList, setExpList] = useState([]);
  useEffect(() => {
    window.addEventListener("resize", setChartWidth);
    setChartWidth();
  }, [profile]);

  useEffect(() => {
    if (profile?.education?.length > 5)
      setEduList(
        (eduShowAll && profile?.education > 5) ||
          profile?.education?.length <= 5
          ? profile?.education
          : profile?.education.slice(0, 5),
      );
    else setEduList(profile?.education);
  }, [profile, eduShowAll]);

  useEffect(() => {
    if (profile?.experience?.length > 5)
      setExpList(
        expShowAll ? profile?.experience : profile?.experience?.slice(0, 5),
      );
    else setExpList(profile?.experience);
  }, [profile, expShowAll]);

  const checkLabel = (label1, label2) =>
    label1 ? label1 : label2 ? label2 : null;

  function setChartWidth() {
    try {
      if (
        d3.select("#candidateAnalytics")?.node()?.getBoundingClientRect().width
      ) {
        setWidth(
          Math.floor(
            d3.select("#candidateAnalytics")?.node()?.getBoundingClientRect()
              .width,
          ) ?? 500,
        );
      } else {
        setTimeout(() => {
          setWidth(500);
        }, 500);
      }
    } catch (err) {
      console.error(err);
      setChartWidth(500);
    }
  }

  return (
    <div className="w-full p-4 grow mb-4">
      {coverLetter && (
        <div className="flex justify-start  flex-col mb-4">
          <h2 className="text-base font-medium text-[#16182f]">Cover letter</h2>
          <p className=" text-sm leading-[21px] text-[#5f6989] mt-3">
            {coverLetter}
          </p>
        </div>
      )}

      <section
        id="profile-section"
        className="w-full  grow  overflow-y-auto md:flex md:gap-4 relative"
      >
        <div
          className={`${profile?.skills?.length > 0 ? "md:w-7/12 shrink" : null} w-full`}
        >
          {/* About section */}
          {profile?.about && (
            <div className="flex justify-start items-stretch flex-col mb-4">
              <h2 className="text-base font-medium text-[#16182f]">About</h2>
              <p className="max-w-[396px] text-sm leading-[21px] text-[#5f6989] mt-3">
                {profile?.about}
              </p>
            </div>
          )}
          {/* Work experience section */}
          {profile?.experience?.length > 0 ? (
            <div className="flex justify-start items-stretch flex-col mb-6 gap-5">
              <h2 className="text-base font-medium text-[#16182f]">
                Work Experience
              </h2>
              {/* Loop starts */}
              {expList?.map((experience, i) => (
                <div
                  className="grid grid-cols-[50px_1fr] gap-x-4 gap-y-1"
                  key={"experience" + i}
                >
                  <InitialsLogo
                    width="50px"
                    height="50px"
                    fontSize="24px"
                    styleClass="rounded-xl border border-[#e1e1ee] row-span-4"
                    str={experience?.company}
                  />
                  <p className="col-start-2 text-base font-semibold text-[#252e4b]">
                    {experience?.position}
                  </p>
                  <p className="col-start-2 text-sm text-[#1369e9]">
                    {experience?.company}
                  </p>
                  <div className="w-full col-start-2 flex flex-row gap-1">
                    <p className="text-sm text-[#252e4b]">
                      {moment(experience?.start).format("MMM YYYY")} -{" "}
                      {experience?.ongoing
                        ? "Present"
                        : moment(experience?.end).format("MMM YYYY")}
                    </p>
                    {/* {experience?.duration && experience?.start && (
                    <p className="text-xs text-[#8d98b1] leading-[22px] px-2">
                      •
                    </p>
                  )}
                  <p className="text-sm text-[#252e4b]">
                    {experience?.duration}
                  </p> */}
                  </div>
                  <ExpandableText
                    maxChar={150}
                    classes="col-start-2 text-sm leading-[22px] text-[#5f6989]"
                  >
                    {experience?.description}
                  </ExpandableText>
                </div>
              ))}
              {profile?.experience?.length > 5 &&
                (expShowAll ? (
                  <button onClick={() => setExpShowAll(false)}>
                    Collapse all
                  </button>
                ) : (
                  <button onClick={() => setExpShowAll(true)}>Show all</button>
                ))}
            </div>
          ) : null}
          {/* Education section */}
          {profile?.education?.length > 0 ? (
            <div className="flex justify-start items-stretch flex-col">
              <h2 className="text-base font-medium text-[#16182f]">
                Education
              </h2>
              {/* Loop starts */}
              {eduList?.map((education, i) => (
                <div
                  className="grid grid-cols-[50px_1fr]  gap-x-2 gap-y-1 mt-3"
                  key={"education" + i}
                >
                  <InitialsLogo
                    width="50px"
                    height="50px"
                    fontSize="24px"
                    styleClass="rounded-xl border border-[#e1e1ee] row-span-4"
                    str={education?.school}
                  />
                  <p className="col-start-2 text-base font-semibold text-[#252e4b]">
                    {education?.degree}
                  </p>
                  <p className="col-start-2 text-sm text-[#1369e9]">
                    {education?.school}
                  </p>
                  <p className="w-full col-start-2 flex flex-row text-sm text-[#252e4b]">
                    {moment(education?.start).format("MMM YYYY")}
                  </p>
                  <ExpandableText
                    maxChar={150}
                    classes="col-start-2 text-sm leading-[22px] text-[#5f6989]"
                  >
                    {education?.description}
                  </ExpandableText>
                </div>
              ))}
              {profile?.education?.length > 5 &&
                (expShowAll ? (
                  <button onClick={() => setEduShowAll(false)}>
                    Collapse all
                  </button>
                ) : (
                  <button onClick={() => setEduShowAll(true)}>Show all</button>
                ))}
            </div>
          ) : null}

          {/* {screeningAnswers?.length > 0 ? (
            <div className="flex justify-start items-stretch flex-col mt-6">
              <h2 className="text-base font-medium text-[#16182f] mb-3">
                Screening Question response
              </h2>

              {screeningAnswers?.map((question, index) => (
                <div
                  key={index}
                  className="w-full flex flex-col items-start mb-3"
                >
                  <span
                    className={`w-full block text-gray-800 font-medium text-sm`}
                  >
                    {`Q${index + 1} : ${question?.question}`}
                  </span>
                  <span className="text-sm text-gray-700 font-semibold w-full">{`Answer : ${question?.candidateResponse}`}</span>
                </div>
              ))}
            </div>
          ) : null} */}
        </div>
        {profile?.skills?.length > 0 && (
          <div
            className="w-full md:w-5/12 mb-4 relative"
            id="candidateAnalytics"
          >
            <h4 className="text-[#16182F] text-[16px] not-italic font-medium">
              Skills
            </h4>
            <div className="w-full">
              {profile?.skills?.length > 0 &&
                profile?.skills?.map((data, it) => (
                  <div className="w-full mb-4" key={it}>
                    <h4 className="text-gray-700 text-sm not-italic font-medium mb-3">
                      {checkLabel(data?.criteria, data?.label)}
                    </h4>
                    <div className="w-full border p-2 border-[#E1E1EE] rounded-xl relative">
                      {checkLabel(data?.skills, data?.keySkills)?.map(
                        (skill, i) => (
                          <div
                            className=" flex last-of-type:pb-0 pb-2 items-center ml-0"
                            key={i}
                          >
                            <span
                              className="w-[45%] text-[#252E4B] text-[14px] not-italic leading-7 truncate overflow-hidden  mr-[10px]"
                              onMouseOver={(e) => {
                                var overflowed =
                                  e.target.scrollWidth > e.target.clientWidth;
                                e.target.title = overflowed
                                  ? e.target.textContent
                                  : "";
                              }}
                              onMouseOut={(e) => (e.target.title = "")}
                            >
                              {skill?.skills}
                            </span>
                            <span className="w-[45%]  mr-[10px]">
                              <ProgressBar
                                id={"skills-bar" + it + i}
                                style_classes="w-full"
                                data={Math.abs(skill?.score)}
                                height={8}
                                barColor={getTypeColor(skill?.type) ?? "teal"}
                                bgColor="#EFF0F2"
                                width={width * 0.45 - 10}
                                fromRight={skill?.score < 0}
                              />
                            </span>
                            <span style={{ width: 20 }}>
                              {Math.floor(skill?.score)}
                            </span>
                          </div>
                        ),
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
      </section>
    </div>
  );
}

function ExpandableText({ children, maxChar = 150, classes }) {
  const [expand, setExpand] = useState(false);

  useEffect(() => {}, [expand]);
  return (
    children && (
      <p className={classes}>
        {children?.length <= maxChar ? (
          children
        ) : expand ? (
          <>
            <span>{children}</span>
            <button
              className="ml-2 p-1 rounded hover:bg-gray-300"
              onClick={() => setExpand(false)}
            >
              <svg width="16" height="16" viewBox="0 0 24 24" fill="none">
                <path
                  d="M5 14H10V19M19 10H14V5"
                  stroke="#252E4B"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </>
        ) : (
          <>
            <span>{String(children).substring(0, maxChar)} ...</span>
            <button
              className="ml-2 px-1 rounded hover:bg-gray-300"
              onClick={() => setExpand(true)}
            >
              <svg width="16" height="16" viewBox="0 0 24 24" fill="none">
                <path
                  d="M10 19H5V14M14 5H19V10"
                  stroke="#252E4B"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </>
        )}
      </p>
    )
  );
}

// [{"name":"Background check","status":true,"question":"Are you willing to undergo a background check, in accordance with local law/regulations?","required":false,"answerType":"yes/no","id":0,"candidateResponse":"Yes"},{"name":"Industry experience","status":true,"question":"Mention your experience in the industry (in years)","required":false,"answerType":"number","id":1,"candidateResponse":"4"},{"name":"Relocate","status":true,"question":"Will you be able to reliably commute or relocate to the job location?","required":false,"answerType":"yes/no","id":3,"candidateResponse":"Yes"}]
