import React, { useContext, useRef } from "react";
import { useState } from "react";
import InterviewReportCard from "./InterviewReportCard";
import AssessmentReport from "./AssessmentReport";
import { useEffect } from "react";
import { CandidatePageContext } from "../../../../lib/context";
import { useSelector } from "react-redux";
const core = require("../../../../lib/core");

const InterviewTestTab = () => {
  const { reload } = useContext(CandidatePageContext);
  const candidate = useSelector((state) => state?.jobCandidateMatch?.profile);
  const [tabName, setTabName] = useState("interview");
  const [scheduledTab, setScheduledTab] = useState(<></>);
  const [reportTab, setReportTab] = useState(<></>);
  // const [dataList, setDataList] = useState([]);

  const getInterviewData = async () => {
    let json = await core.API(
      core.API_METHODS.POST,
      core.RECRUITER_API.GET_INTERVIEWS,
      1,
      {
        match_profile_id: candidate?.matchProfile?._id,
        status: "completed",
      },
    );
    setReportTab(
      <React.Suspense fallback={<div>Loading...</div>}>
        <InterviewReportCard
          data={json?.data?.filter((iter) => iter?.status == "completed")}
          // data={interviewData}
        />
      </React.Suspense>,
    );
  };

  useEffect(() => {
    switch (tabName) {
      case "interview": {
        getInterviewData();
        setReportTab(
          <React.Suspense fallback={<div>Loading...</div>}>
            <InterviewReportCard
            // data={json?.data?.filter((iter) => iter?.status == "completed")}
            // data={interviewData}
            />
          </React.Suspense>,
        );
        break;
      }
      default: {
        setScheduledTab();
        setReportTab(
          <React.Suspense fallback={<div>Loading...</div>}>
            <AssessmentReport data={[]} />
          </React.Suspense>,
        );
        break;
      }
    }
  }, [tabName, reload, candidate?.matchProfile?._id]);

  return (
    <section
      id="interviewTest"
      className="w-full p-4 min-h-[calc(100vh-207px)] bg-defaultBg"
    >
      <div className="bg-customColor rounded-lg py-px px-px inline-flex items-center mb-4">
        <button
          disabled
          onClick={() => setTabName("assessment")}
          className={`px-4 py-2 flex items-center justify-center ${
            tabName === "assessment" ? "bg-white rounded-lg" : "bg-transparent"
          } `}
        >
          Assessments
        </button>
        <button
          onClick={() => setTabName("interview")}
          className={`px-4 py-2 flex items-center justify-center ${
            tabName === "interview" ? "bg-white rounded-lg" : "bg-transparent"
          } `}
        >
          Interviews
        </button>
      </div>
      <div className="my-4 w-full">
        <h2 className="text-gray-700 text-sm not-italic font-normal">
          Reports
        </h2>
        <div className="w-full bg-white rounded-lg border-b border-gray-100 p-4">
          {reportTab}
        </div>
      </div>
    </section>
  );
};

export default InterviewTestTab;
