import React, { useEffect, useState } from "react";
import {
  ANSWERTYPES,
  defaultLikertValues,
  defaultYesNoValues,
} from "../../../lib/constants";

const capitalize = (str) => str?.charAt(0)?.toUpperCase() + str?.slice(1);

const OneChoiceAnswer = ({
  answer = ["Yes", "No"],
  candidateAnswer,
  index,
  required,
  theme,
  isTemplateUsed,
}) => {
  const checkedColor =
    "bg-[" +
    theme +
    "] checked:bg-[" +
    theme +
    "] checked:border-[" +
    theme +
    "] hover:bg-[" +
    theme +
    "] hover:border-[" +
    theme +
    "]";

  return answer?.map((opt, idx) => (
    <div className="w-full flex gap-2 items start">
      <input
        // style={
        //   isTemplateUsed
        //     ? { borderColor: "gray", outlineColor: "gray", borderWidth: "2px" } // If isTemplateUsed is true
        //     : {} // If isTemplateUsed is false
        // }
        required={required}
        onChange={(e) => candidateAnswer(index, opt)}
        id={"qu" + index + idx}
        name={"qu" + index}
        type="radio"
        className={`w-4 h-4 p-1 ${isTemplateUsed ? "border-gray-500 focus:border-gray-500" : theme ? checkedColor : ""} ${isTemplateUsed ? "border-2" : "border-0"}`}
      />

      <label htmlFor={"qu" + index + idx} className="text-sm font-normal mb-0">
        {capitalize(opt)}
      </label>
    </div>
  ));
};

const MCQAnswer = ({
  question,
  answer,
  candidateAnswer,
  index,
  required,
  theme,
  isTemplateUsed,
}) => {
  const checkedColor =
    "bg-[" +
    theme +
    "] checked:bg-[" +
    theme +
    "] checked:border-[" +
    theme +
    "] hover:bg-[" +
    theme +
    "] hover:border-[" +
    theme +
    "]";

  const [requiredCheck, setRequiredCheck] = useState(required);
  console.log(question[index]?.candidateResponse, requiredCheck, required);
  useEffect(() => {
    if (required) {
      if (question[index]?.candidateResponse?.length > 0)
        setRequiredCheck(false);
      else setRequiredCheck(true);
    }
    console.log("after", requiredCheck);
  }, [question[index]?.candidateResponse]);

  const setAnswer = (event) => {
    question?.map((qu, idx) => {
      if (idx === index) {
        var answers = qu?.candidateResponse ? [...qu?.candidateResponse] : [];
        if (event?.target?.checked) answers.push(event?.target?.value);
        else answers = answers.filter((ans) => ans !== event?.target?.value);
        candidateAnswer(index, answers);
      }
    });
  };

  return answer?.map((opt, idx) => (
    <div className="w-full flex gap-2 items start">
      <input
        required={requiredCheck}
        // style={
        //   isTemplateUsed
        //     ? { borderColor: "gray", outlineColor: "gray", borderWidth: "2px" } // If isTemplateUsed is true
        //     : { borderColor: theme, outlineColor: theme } // If isTemplateUsed is false
        // }
        onChange={setAnswer}
        id={"qu" + index + idx}
        name={"qu" + index}
        value={opt}
        type="checkbox"
        className={`w-4 h-4 p-1 ${isTemplateUsed ? "border-gray-500 focus:border-gray-500" : checkedColor ?? ""} ${isTemplateUsed ? "border-2" : "border-0"}`}
      />

      <label htmlFor={"qu" + index + idx} className="text-sm font-normal mb-0">
        {capitalize(opt)}
      </label>
    </div>
  ));
};

const LikertScale = ({
  answer = defaultLikertValues,
  candidateAnswer,
  index,
  required,
  theme,
  isTemplateUsed,
}) => {
  const checkedColor =
    "bg-[" +
    theme +
    "] checked:bg-[" +
    theme +
    "] checked:border-[" +
    theme +
    "] hover:bg-[" +
    theme +
    "] hover:border-[" +
    theme +
    "]";

  return (
    <div className="w-full flex gap-2 items-start justify-between">
      {answer?.map((opt, idx) => (
        <div className="flex flex-col gap-2 items-center justify-start">
          <input
            // style={
            //   isTemplateUsed
            //     ? { borderColor: "gray", outlineColor: "gray", borderWidth: "2px" } // If isTemplateUsed is true
            //     : { borderColor: theme, outlineColor: theme } // If isTemplateUsed is false
            // }
            required={required}
            onChange={(e) => candidateAnswer(index, opt?.name)}
            id={"qu" + index + idx}
            name={"qu" + index}
            type="radio"
            className={`w-4 h-4 p-1 ${isTemplateUsed ? "border-gray-500 focus:border-gray-500" : checkedColor ?? ""} ${isTemplateUsed ? "border-2" : "border-0"}`}
          />

          <label
            htmlFor={"qu" + index + idx}
            className="text-sm font-normal mb-0"
          >
            {capitalize(opt?.name)}
          </label>
        </div>
      ))}{" "}
    </div>
  );
};

const ParagraghAnswer = ({
  index,
  candidateAnswer,
  type,
  required,
  theme,
  isTemplateUsed,
}) => {
  console.log("theme is ", theme);
  return (
    <div className="w-full">
      <input
        required={required}
        type={type}
        onChange={(e) => candidateAnswer(index, e.target.value)}
        className={`mt-1 p-3 hover:shadow-md rounded-lg w-full placeholder-gray-600 transition-colors 
    ${
      isTemplateUsed
        ? "border-0 border-gray-500 focus:border-gray-500 focus:border-2"
        : `border focus:border-2 hover:border-2`
    }`}
        style={
          !isTemplateUsed ? { borderColor: theme, outlineColor: theme } : {}
        }
      />
    </div>
  );
};

const Answer = ({
  answer,
  required,
  candidateAnswer,
  answerType,
  index,
  question,
  theme,
  isTemplateUsed,
}) => {
  switch (answerType) {
    case ANSWERTYPES?.RADIO:
      return (
        <OneChoiceAnswer
          index={index}
          theme={theme}
          required={required}
          answer={answer}
          isTemplateUsed={isTemplateUsed}
          candidateAnswer={candidateAnswer}
        />
      );
    case ANSWERTYPES?.YESNO:
      return (
        <OneChoiceAnswer
          index={index}
          theme={theme}
          required={required}
          answer={answer && answer?.length > 0 ? answer : defaultYesNoValues}
          isTemplateUsed={isTemplateUsed}
          candidateAnswer={candidateAnswer}
        />
      );
    case ANSWERTYPES?.MCQ:
      return (
        <MCQAnswer
          question={question}
          index={index}
          theme={theme}
          required={required}
          answer={answer}
          isTemplateUsed={isTemplateUsed}
          candidateAnswer={candidateAnswer}
        />
      );
    case ANSWERTYPES?.LIKERT:
      return (
        <LikertScale
          index={index}
          theme={theme}
          required={required}
          isTemplateUsed={isTemplateUsed}
          answer={answer && answer?.length > 0 ? answer : defaultLikertValues}
          candidateAnswer={candidateAnswer}
        />
      );
    default:
      return (
        <ParagraghAnswer
          index={index}
          theme={theme}
          required={required}
          type={answerType}
          isTemplateUsed={isTemplateUsed}
          candidateAnswer={candidateAnswer}
        />
      );
  }
};

export default Answer;
