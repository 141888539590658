import React, { useContext, useEffect, useState } from "react";
import Modal from "../../../modal/Modal";
import InputBox from "../../../../layouts/InputBox";
import SelectBox from "../../../../layouts/SelectBox";
import { ScaleLoader } from "react-spinners";
import { JobPageContext } from "../../../../lib/context";
import { MdOutlineFileUpload } from "react-icons/md";

const core = require("../../../../lib/core");

const FileInput = ({ setFile, file, loading }) => {
  function handleChange(event) {
    setFile(event.target.files[0]);
  }

  return (
    <div className="mb-6">
      <label className="block text-sm font-medium text-gray-700">
        Attach resume
      </label>
      <div className="flex items-center space-x-3">
        <label
          htmlFor="formFile"
          className="px-4 py-2 bg-gray-100 cursor-pointer text-gray-700 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 flex items-center"
          style={{ backgroundColor: "#F3F5F9" }}
        >
          <MdOutlineFileUpload size={18} className="mr-2" />
          Choose file
        </label>

        <input
          onChange={handleChange}
          className="hidden"
          type="file"
          id="formFile"
          accept="application/pdf"
        />

        {loading ? (
          <p className="text-sm mt-1 text-blue-700">Uploading...</p>
        ) : (
          <>
            {file ? (
              <p className="text-sm mt-1 text-blue-700">
                {file?.name}
                <span className="text-sm text-gray-500"> {file?.size} Kb</span>
              </p>
            ) : (
              <span className="text-sm text-gray-500">No file chosen</span>
            )}
          </>
        )}
      </div>
    </div>
  );
};

function ChangeCandidatesDataModal({
  sourcecomp,
  candidateDetail,
  jobId,
  modalDismiss,
  pipelines,
  loadData,
  from,
  setCandidatesList,
}) {
  const { setContentReload } = useContext(JobPageContext);

  const [name, setName] = useState("");
  const [last_name, setLast_Name] = useState("");
  const [title, setTitle] = useState("");
  const [emails, setEmails] = useState([]);
  const [phones, setPhones] = useState([]);
  const [linkedinurls, setLinkedinurls] = useState("");
  const [source, setSource] = useState("");
  const [remoteSource, setRemoteSource] = useState("");
  const [prospect, setProspect] = useState("Yes");
  const [loading, setLoading] = useState(false);
  const [stage, setStage] = useState("");
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (candidateDetail) {
      setName(candidateDetail?.name || "");
      setLast_Name(candidateDetail?.last_name || "");
      setTitle(candidateDetail?.title || "");
      setEmails(candidateDetail?.email || []);
      setPhones(candidateDetail?.phone || []);
      setLinkedinurls(candidateDetail?.linkedln || "");
      setSource(candidateDetail?.source || "");
      setRemoteSource(candidateDetail?.remote_source || "");
    }
  }, [candidateDetail]);
  console.log("remoteSource", remoteSource);

  const updateNotes = async () => {
    setLoading(true);
    try {
      if (file) {
        await attachResume(file);
      }
      const json = await core.API(
        core.API_METHODS.PUT,
        core.JOB_API.PUT_UPDATE_CANDIDATE_DETAILS +
          candidateDetail?.id +
          "/" +
          candidateDetail?.matchProfileId,
        1,
        {
          first_name: name,
          last_name,
          title,
          email: emails?.length > 0 && emails[0]?.length > 1 ? emails : [],
          phone: phones?.length > 0 && phones[0]?.length > 1 ? phones : [],
          linkedin: linkedinurls,
          source,
          remoteSource,
        },
      );
      if (json?.data) {
        setLoading(false);
        loadData(json?.data);
        console.log("modal dismiss");
        modalDismiss();
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  const createMatchprofileManually = async () => {
    setLoading(true);

    try {
      const json = await core.API(
        core.API_METHODS.POST,
        core.RECRUITER_API.CREATE_MATCH_PROFILE_MANUALLY,
        1,
        {
          job: jobId,
          first_name: name,
          last_name,
          title,
          email: emails?.length > 0 && emails[0]?.length > 1 ? emails : [],
          phone: phones?.length > 0 && phones[0]?.length > 1 ? phones : [],
          linkedin: linkedinurls,
          remoteSource,
          stage,
          prospect,
        },
      );
      console.log("json", json);
      if (json?.data) {
        setLoading(false);
        // loadData(json?.data);
        modalDismiss();
        setContentReload((prev) => prev + 1);
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  const attachResume = async (file) => {
    let formData = new FormData();
    formData.append("files", file);
    formData.append("candidate_details_id", candidateDetail?.id);

    setLoading(true);
    try {
      console.log(formData);
      const token = await core.getData(core.data.ACCESS_TOKEN);
      let res = await fetch(core.RECRUITER_API.ATTACH_RESUME, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
        },
        body: formData,
      });

      if (res.status === 200) {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  return (
    <Modal
      heading={from ? "Add Candidate" : "Edit Candidate Detail"}
      onSave={from ? createMatchprofileManually : updateNotes}
      noBtns={name?.trim()?.length == 0}
      subheading={
        from
          ? ""
          : "Edit the candidate's details if any information was parsed incorrectly."
      }
      modalDismiss={modalDismiss}
      defaultButtons={{
        primaryLabel: loading ? (
          <ScaleLoader height={14} color="#ffffff" loading={true} />
        ) : (
          "Save"
        ),
        primaryDisabled: loading,
      }}
      className={"md:max-w-[60%]"}
    >
      {!candidateDetail?.resume_link && !from && (
        <FileInput
          attachResume={attachResume}
          file={file}
          setFile={setFile}
          loading={loading}
        />
      )}
      <div className="p-1  rounded-lg   grid grid-cols-2 gap-y-4 gap-x-4">
        <div className="">
          <label className="block text-sm font-medium text-gray-700 required">
            First name
          </label>
          <InputBox
            placeholder={"Enter first name"}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="">
          <label className="block text-sm font-medium text-gray-700">
            Last name
          </label>
          <InputBox
            placeholder={"Enter last name"}
            value={last_name}
            onChange={(e) => setLast_Name(e.target.value)}
          />
        </div>
        <div className="">
          <label className="block text-sm font-medium text-gray-700">
            Title
          </label>
          <InputBox
            placeholder={"Enter title"}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        {/* <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Current Company</label>
          <InputBox
            className="mt-1"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div> */}
        <div className="">
          <label className="block text-sm font-medium text-gray-700">
            Email
          </label>
          {emails.length > 1 ? (
            emails.map((email, index) => (
              <InputBox
                placeholder={"Enter email"}
                key={index}
                value={email}
                onChange={(e) => {
                  const newEmails = [...emails];
                  newEmails[index] = e.target.value;
                  setEmails(newEmails);
                }}
              />
            ))
          ) : (
            <InputBox
              placeholder={"Enter email"}
              value={emails[0] ?? ""}
              onChange={(e) => {
                const newEmails = [...emails];
                newEmails[0] = e.target.value;
                setEmails(newEmails);
              }}
            />
          )}
        </div>
        <div className="">
          <label className="block text-sm font-medium text-gray-700">
            Phone
          </label>
          {phones.length > 1 ? (
            phones.map((phone, index) => (
              <InputBox
                placeholder={"Enter phone"}
                key={index}
                value={phone}
                onChange={(e) => {
                  const newPhones = [...phones];
                  newPhones[index] = e.target.value;
                  setPhones(newPhones);
                }}
              />
            ))
          ) : (
            <InputBox
              placeholder={"Enter phone"}
              value={phones[0] ?? ""}
              onChange={(e) => {
                const newPhones = [...phones];
                newPhones[0] = e.target.value;
                setPhones(newPhones);
              }}
            />
          )}
        </div>
        <div className="">
          <label className="block text-sm font-medium text-gray-700">
            LinkedIn URL
          </label>
          <InputBox
            placeholder={"Enter linkedin url"}
            value={linkedinurls}
            onChange={(e) => setLinkedinurls(e.target.value)}
          />
        </div>

        {from && (
          <div className="">
            <label className="block text-sm font-medium text-gray-700">
              Prospect
            </label>
            <SelectBox
              value={prospect}
              options={["Yes", "No"]}
              onChange={(e) => setProspect(e.target.value)}
            />
          </div>
        )}

        {from && prospect == "No" && (
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Initial stage
            </label>
            <SelectBox
              className="mt-1"
              value={stage}
              options={pipelines?.filter((x) => x != "Rejected")}
              onChange={(e) => setStage(e.target.value)}
            />
          </div>
        )}
        {
          <>
            {
              <div className="">
                <label className="block text-sm font-medium text-gray-700">
                  Source
                </label>
                <SelectBox
                  className="mt-1"
                  value={source}
                  options={[
                    "Greenhouse",
                    "Applied",
                    "Sprouts",
                    "Recruiter",
                    "None",
                  ]}
                  onChange={(e) => setSource(e.target.value)}
                />
              </div>
            }
            <div className="">
              <label className="block text-sm font-medium text-gray-700">
                Remote source
              </label>
              <SelectBox
                className="mt-1"
                value={remoteSource}
                options={["Internal Applicant", "Referral", "None"]}
                onChange={(e) => setRemoteSource(e.target.value)}
              />
            </div>
          </>
        }
      </div>
    </Modal>
  );
}

export default ChangeCandidatesDataModal;
