import React from "react";

function TextAreaBox({
  value,
  placeholder,
  required,
  onChange,
  type,
  width,
  size,
  className,
}) {
  return (
    <div>
      <textarea
        className={`w-[${width ? width : "100%"}] w-full h-[${size}] resize-none  border border-gray-400 p-2 rounded-lg text-base shadow-[0px_2px_0px_rgba(0,0,0,0.04)] active:border-[#1369E9] focus:border-[#1369E9] focus:shadow-inputShadow placeholder:text-[16px] placeholder:font-normal placeholder:text-[#5F6989] appearance-none ${className}`}
        type={type ?? "text"}
        name="title"
        value={value}
        required={required ?? false}
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  );
}

export default TextAreaBox;
