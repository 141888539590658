import React, { useContext, useEffect } from "react";
import SvgIcon3 from "./icons/SvgIcon3";
import SvgIcon5 from "./icons/SvgIcon5";
import SvgIcon6 from "./icons/SvgIcon6";
import SvgIcon9 from "./icons/SvgIcon9";
import InitialsLogo from "../../all/InitialsLogo";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ScheduleInterview from "../applicant/ScheduleInterview";
import InviteModal from "../job/assessment/InviteModal";
import { CandidatePageContext } from "../../../lib/context";
import { notes } from "./icons/Icons";
import ChangeCandidatesDataModal from "../job/candidates/ChangeCandidatesDataModal";
import { updateProfile_MatchProfile } from "../../../redux/slices/JobMatchCandidatesSlice";
import RejectedReasonModal from "../applicant/RejectedReasonModal";
import SendEmail from "../applicant/SendEmail";
const core = require("../../../lib/core");

const TopBanner = ({
  setActiveTab,
  btnList,
  candId,
  setCandidates,
  reviewMode,
  pipeline,
  showActivities = true,
  showActions = true,
}) => {
  const { setShowModal } = useContext(CandidatePageContext);
  const [activeTabName, setActiveTabName] = useState("Details");
  const [showMenu, setShowMenu] = useState(false);
  const Profile = useSelector((state) => state?.jobCandidateMatch?.profile);
  const [Modal, setModal] = useState("");
  const dispatch = useDispatch();
  const [profile, setprofile] = useState(Profile);
  const handleCandidateEdit = (updatedData) => {
    dispatch(
      updateProfile_MatchProfile({
        email: updatedData.email,
        phone: updatedData.phone,
        first_name: updatedData.first_name,
        last_name: updatedData.last_name,
        title: updatedData.title,
        resume_link: updatedData.resume_link,
        current_source: updatedData.current_source,
        remote_source: updatedData.remote_source,
      }),
    );
  };
  useEffect(() => {
    setprofile(Profile);
  }, [Profile]);
  console.log(profile);
  const [chatDetail, setChatDetail] = useState({});
  const [jobId, setJobId] = useState("");
  const [candidateDetail, setCandidateDetail] = useState({});
  const [sendText, setSendText] = useState("");
  const modalClose = () => {
    setModal("");
  };

  async function updateStatus(
    cid,
    status,
    reason,
    email_template_id,
    rejection_reason_id,
    onBehalfId,
    when,
    send_email,
    subject,
  ) {
    setSendText("Sending...");
    await core
      .API(
        core.API_METHODS.PUT,
        core.RECRUITER_API.PUT_UPDATE_APPLICANT_STATUS,
        1,
        {
          matchprofileIds: [cid],
          jobId: profile?.matchProfile?.job,
          current_stage: status,
          rejectionNotes: reason,
          email_template_id: email_template_id,
          rejection_reason_id: rejection_reason_id,
          onBehalfId: onBehalfId,
          when: when,
          send_email: send_email,
          subject: subject,
        },
      )
      .then((json) => {
        if (json.data) {
          setprofile((prevData) => ({
            ...prevData,
            matchProfile: {
              ...prevData.matchProfile,
              current_stage: status,
            },
          }));
          setSendText("");
          modalClose();
          // if (!reason && candidateDetail?.stage === "Application Review") {
          //   setShowModal("add-notes-modal");
          // }
        } else {
        }
      });
  }
  return (
    // profile && (
    <>
      <div
        id="topBanner"
        className="w-full relative px-6 border-t-0 border-x-0 border-b border-[#e1e1ee] border-solid"
      >
        <div className="relative flex justify-start items-start flex-row gap-4 max-w-full w-full">
          <div className="relative grow flex flex-col justify-start items-start gap-2 w-auto">
            <div className="relative flex justify-start items-start flex-row gap-4">
              <InitialsLogo
                width="56px"
                height="56px"
                fontSize="24px"
                styleclassName="block rounded-xl border-[none]"
                str={profile?.matchProfile?.first_name}
              />
              <div className="relative grow flex flex-col flex-wrap max-w-full">
                <div className="flex items-center gap-4">
                  <span className="relative text-lg font-medium text-[#252e4b]">
                    {profile?.matchProfile?.first_name}{" "}
                    {profile?.matchProfile?.last_name ?? ""}
                  </span>
                  <div className="flex items-center gap-2.5">
                    {profile?.matchProfile?.linkedin && (
                      <a
                        className="btn-sec w-8 h-8 px-1"
                        href={profile?.matchProfile?.linkedin}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-4 h-4"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M4.35313 3.55556C4.35295 3.89233 4.2138 4.21523 3.96629 4.45324C3.71878 4.69125 3.38318 4.82487 3.03333 4.8247C2.68347 4.82453 2.34801 4.69059 2.10075 4.45234C1.85348 4.21409 1.71467 3.89106 1.71484 3.55429C1.71502 3.21753 1.85417 2.89462 2.10168 2.65661C2.34919 2.41861 2.68479 2.28499 3.03465 2.28516C3.3845 2.28532 3.71996 2.41927 3.96723 2.65751C4.21449 2.89576 4.3533 3.2188 4.35313 3.55556ZM4.3927 5.76496H1.75442V13.7137H4.3927V5.76496ZM8.56119 5.76496H5.9361V13.7137H8.53481V9.54253C8.53481 7.21885 11.681 7.00299 11.681 9.54253V13.7137H14.2863V8.67909C14.2863 4.76184 9.6297 4.90787 8.53481 6.83157L8.56119 5.76496Z"
                            fill="#5F6989"
                          />
                        </svg>
                      </a>
                    )}
                    {profile?.matchProfile?.github && (
                      <a href={profile?.matchProfile?.github}>
                        <SvgIcon5 className="w-8 h-8" />
                      </a>
                    )}
                    {profile?.matchProfile?.email?.length > 0 && (
                      <button
                        className="cursor-pointer"
                        onClick={() => {
                          setModal("send-email");
                        }}
                      >
                        <SvgIcon6 className="w-8 h-8" />
                      </button>
                    )}

                    {
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          setModal("Update_Can_data");
                        }}
                      >
                        <SvgIcon9 className="w-8 h-8" />
                      </div>
                    }
                  </div>
                </div>
                {profile?.matchProfile?.title && (
                  <span className="text-sm overflow-ellipsis text-[#5f6989] mt-1.5">
                    {profile?.matchProfile?.title}
                  </span>
                )}
              </div>
            </div>
            {/* Links and buttons */}
            {/* <div className="flex justify-start items-stretch flex-row gap-2.5 h-8 mt-2"> */}
            {/* <div
              className={`bg-[rgba(0,172,133,0.10)] flex justify-start items-center flex-row gap-1.5 px-2 rounded-lg`}
            >
              <SvgIcon1 className="w-[18px] h-[18px] text-[#00ac85] flex" />
              <p className={` text-sm font-medium text-[#252e4b]`}>
                {profile?.matchProfile?.overallMatch * 100}% Match
              </p>
            </div> */}
            {/* {profile?.matchProfile?.linkedin && (
                <a
                  className="btn-sec w-8 h-8 px-1"
                  href={profile?.matchProfile?.linkedin}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 h-4"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M4.35313 3.55556C4.35295 3.89233 4.2138 4.21523 3.96629 4.45324C3.71878 4.69125 3.38318 4.82487 3.03333 4.8247C2.68347 4.82453 2.34801 4.69059 2.10075 4.45234C1.85348 4.21409 1.71467 3.89106 1.71484 3.55429C1.71502 3.21753 1.85417 2.89462 2.10168 2.65661C2.34919 2.41861 2.68479 2.28499 3.03465 2.28516C3.3845 2.28532 3.71996 2.41927 3.96723 2.65751C4.21449 2.89576 4.3533 3.2188 4.35313 3.55556ZM4.3927 5.76496H1.75442V13.7137H4.3927V5.76496ZM8.56119 5.76496H5.9361V13.7137H8.53481V9.54253C8.53481 7.21885 11.681 7.00299 11.681 9.54253V13.7137H14.2863V8.67909C14.2863 4.76184 9.6297 4.90787 8.53481 6.83157L8.56119 5.76496Z"
                      fill="#5F6989"
                    />
                  </svg>
                </a>
              )}
              {profile?.matchProfile?.github && (
                <a href={profile?.matchProfile?.github}>
                  <SvgIcon5 className="w-8 h-8" />
                </a>
              )}
              {profile?.matchProfile?.email?.length > 0 && showActions && (
                <a href={profile?.matchProfile?.email[0]}>
                  <SvgIcon6 className="w-8 h-8" />
                </a>
              )}

              {showActions && (
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    setModal("Update_Can_data");
                  }}
                >
                  <SvgIcon9 className="w-8 h-8" />
                </div>
              } */}
            {/* {!profile?.link && (
              )}
              {/* {!profile?.link && (
            <a href={profile?.link}>
              <SvgIcon7 className="w-8 h-8 flex" />
            </a>
          )} */}
            {/* Button Component is detected here. We've generated code using HTML. See other options in "Component library" dropdown in Settings */}
            {/* <button className="w-[78px] h-8 border bg-white shadow-[0px_1px_0px_rgba(0,0,0,0.04)] text-sm text-[#5f6989] cursor-pointer inline-flex items-center justify-center gap-1.5 rounded-lg border-[#e1e1ee] border-solid">
            <SvgIcon8 className="w-5 h-5 text-[#5f6989] flex" />
            Chat
          </button> */}
            {/* </div> */}
          </div>
          <div className="relative flex flex-col grow justify-end items-stretch gap-3 w-auto">
            {/* <div className="flex justify-end items-stretch flex-row gap-3 h-10">
              <button
                onClick={() => setShowMenu((prev) => !prev)}
                className="btn-md btn-sec shadow-[0px_2px_0px_rgba(0,0,0,0.04)]"
              >
                <span className="text-base text-[#252e4b]">
                  {profile?.matchProfile?.current_stage}
                </span>
                <SvgIcon3 className="w-4 h-4 text-[#252e4b] flex" />
                {showMenu && (
                  <div className="top-[40px] bg-white absolute w-[200px] p-1 rounded-lg border border-[#dadae4] shadow-[0px_2px_0px_rgba(0,0,0,0.04)]">
                    {pipeline
                      ?.filter((item) => item != "Rejected")
                      ?.map?.((stage) => (
                        <button
                          className="w-full text-sm text-gray-800 hover:bg-gray-300 px-2 py-1 text-left"
                          onClick={() => {
                            if (stage === "Rejected") {
                              setModal("reject-reason-status");
                              setChatDetail({
                                id: profile?.matchProfile?._id,
                                email: profile?.applicant?.email,
                                name: profile?.applicant?.first_name,
                              });
                            } else {
                              updateStatus(
                                profile?.matchProfile?._id,
                                stage,
                                ""
                              );
                            }
                          }}
                        >
                          {stage}
                        </button>
                      ))}
                  </div>
                )}
              </button>
              {profile?.matchProfile?.current_stage !== "Rejected" && (
                <button
                  onClick={(e) => {
                    setModal("reject-reason-status");
                    setChatDetail({
                      id: profile?.matchProfile?._id,
                      email: profile?.applicant?.email,
                      name: profile?.applicant?.first_name,
                    });
                  }}
                  className="btn-md btn-sec text-red-900 hover:bg-red-900 hover:text-white hover:border-red-900"
                >
                  Reject
                </button>
              )}
            </div> */}

            <div className="flex justify-end items-stretch flex-row gap-3 h-10">
              {/* Invite candidate for assessment */}
              {/* {activeAssessments?.length > 0 && (
                <button
                  onClick={() => setShowModal({type:"assessment"})}
                  className="border shadow-[0px_2px_0px_rgba(0,0,0,0.04)] bg-white flex justify-center items-stretch flex-col px-2.5 rounded-lg border-[#dadae4] border-solid"
                >
                  <SvgIcon1 className="w-5 h-5 text-[#252e4b] flex" />
                </button>
              )} */}
              {/* Schedule interview candidate */}
              {/* <button
              data-tooltip-id="tooltip"
              data-tooltip-content={"Schedule interview"}
              onClick={() => setShowModal({ type: "interview", edit: false })}
              className="btn-md btn-sec w-10 px-2"
            >
              <SvgIcon2 className="w-5 h-5 text-[#252e4b] flex" />
            </button> */}
              <button
                data-tooltip-id="tooltip"
                data-tooltip-content={"Add notes"}
                onClick={() => setShowModal({ type: "notes" })}
                className="btn-md btn-sec w-10 p-[9px]"
              >
                {notes}
              </button>
              {showActions && (
                <button
                  data-tooltip-id="tooltip"
                  data-tooltip-content={"Review mode"}
                  onClick={() => reviewMode()}
                  className="btn-md btn-sec  p-[9px]"
                >
                  {"Review mode"}
                </button>
              )}
            </div>
          </div>
        </div>

        {/* Tabs list */}
        <div
          data-testid="btnList"
          className="flex justify-start items-center flex-row gap-6 mt-4"
        >
          {btnList.map((btn) => (
            <button
              onClick={() => {
                setActiveTab(btn.component);
                setActiveTabName(btn.name);
              }}
              data-testid={String(btn.name).toLowerCase()}
              className={`text-base font-medium pt-2 pb-3.5 border-t-0 border-b-2 border-x-0 ${
                btn.name == activeTabName
                  ? "border-[#1369e9] text-[#16182f]"
                  : "border-transparent text-[#252e4b]"
              } border-solid hover:border-blue-600`}
            >
              {btn?.name}
            </button>
          ))}
        </div>
      </div>
      {Modal === "Update_Can_data" ? (
        <>
          <ChangeCandidatesDataModal
            candidateDetail={{
              id: profile?.applicant_id,
              matchProfileId: profile?.matchProfile?._id,
              name: profile?.matchProfile?.first_name,
              last_name: profile?.matchProfile?.last_name,
              title: profile?.matchProfile?.title,
              email: profile?.matchProfile?.email,
              phone: profile?.matchProfile?.phone,
              linkedln: profile?.matchProfile?.linkedin,
              source: profile?.matchProfile?.current_source,
              remote_source: profile?.matchProfile?.remote_source,
            }}
            loadData={(updatedData) => {
              handleCandidateEdit(updatedData);
              setCandidates(updatedData, candId);
            }}
            modalDismiss={() => setModal("")}
          />
        </>
      ) : Modal == "reject-reason-status" ? (
        <RejectedReasonModal
          updateRejectedStatus={updateStatus}
          modalDismiss={modalClose}
          chatDetail={chatDetail}
          jobId={profile?.matchProfile?.job}
          candidateId={chatDetail?.id}
        />
      ) : Modal == "send-email" ? (
        <SendEmail
          chatDetail={profile?.matchProfile}
          // companydata={companydata}
          candidateId={candidateDetail?.id}
          modalDismiss={modalClose}
        />
      ) : null}
    </>
    // )
  );
};

export default TopBanner;
