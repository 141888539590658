import React from "react";

function InputBox({
  required,
  type,
  width,
  size,
  ref,
  onFocus,
  onKeyDown,
  className,
  disabled,
  placeholder,
  onKeyPress,
  multiline,
  rows,
  ...props
}) {
  const commonProps = {
    className: `w-[${width ? width : "100%"}] ${className} h-[${size === "medium" ? "40px" : size === "small" ? "32px" : multiline ? "auto" : "48px"}] border border-gray-400 p-2 rounded-lg text-base shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder:text-[14px] placeholder:font-normal active:border-[#1369E9] focus:border-[#1369E9] focus:shadow-inputShadow placeholder:text-[#A9A9AC] `,
    required: required ?? false,
    placeholder: placeholder ?? "Enter",
    disabled: disabled,
    onKeyDown: onKeyDown,
    onFocus: onFocus,
    ref: ref,
    onKeyPress: onKeyPress,
    ...props,
  };

  return (
    <>
      {multiline ? (
        <textarea rows={rows ?? 4} {...commonProps} />
      ) : (
        <input type={type ?? "text"} {...commonProps} />
      )}
    </>
  );
}

export default InputBox;
