import LoginPageImg from "../../components/assets/candidate/LogInPageImg.png";
import LandingText from "./LandingText";
export default function Landing() {
  return (
    <div className="landing-backdrop no-padding">
      <LandingText />
      <img
        src={LoginPageImg}
        // src="https://sproutsweb-assets.s3.amazonaws.com/common-assets/Data+extraction-pana+1.png"
        className="img-responsive"
        alt="AI based decision engine"
      />
    </div>
  );
}
